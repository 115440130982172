<template>
  <el-steps v-bind="{ ...$attrs, ...props }" class="ui-steps">
    <template #default>
      <slot name="default"></slot>
    </template>
  </el-steps>
</template>
<script setup lang="ts">
import { stepsProps } from 'element-plus'

const props = defineProps({
  ...stepsProps,
})
</script>
<style lang="postcss" scoped>
.ui-steps.el-steps {
}
</style>
