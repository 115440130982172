<template>
  <div class="account-info">
    <ui-flexbox direction="column" :gap="4">
      <AddressCopy
        :address
        variant="body1"
        color="textPrimary"
        ellipsis
        :address-format-options="{ start: 15, end: 4, dots: 3 }"
      />
      <ui-text variant="body3" color="textTertiary">
        Connected with Metamask
      </ui-text>
    </ui-flexbox>
    <ui-flexbox
      full-width
      align="center"
      :justify="{ sm: 'space-between', md: 'flex-end', lg: 'flex-end' }"
    >
      <ui-text variant="h5" color="textPrimary" class="account-info__title">
        Account
      </ui-text>
      <ui-button size="large" @click="disconnectWallet">Disconnect</ui-button>
    </ui-flexbox>
  </div>
</template>
<script lang="ts" setup>
import AddressCopy from '@base/components/business/address-copy'
const { address, disconnectWallet } = useWallet()
</script>
<style lang="postcss" scoped>
.account-info {
  display: flex;
  flex-direction: column-reverse;
  gap: 1.5rem;
}
@media screen and (min-width: 768px) {
  .account-info {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .account-info__title {
    display: none;
  }
}
</style>
