export function useFaucetState() {
  const isFaucetAlertVisibleStorage = useSessionStorage(
    'faucet-alert-visible',
    true,
  )

  const hideFaucetAlert = () => (isFaucetAlertVisibleStorage.value = false)

  const isFaucetAlertPermanentlyHidden = useLocalStorage(
    'faucet-alert-perm-hidden',
    false,
    { mergeDefaults: true },
  )

  const permanentlyHideFaucetAlert = () =>
    (isFaucetAlertPermanentlyHidden.value = true)

  const isFaucetAlertVisible = computed(
    () =>
      isFaucetAlertVisibleStorage.value &&
      !isFaucetAlertPermanentlyHidden.value,
  )

  return {
    hideFaucetAlert,
    isFaucetAlertVisible,
    isFaucetAlertVisibleStorage,
    isFaucetAlertPermanentlyHidden,
    permanentlyHideFaucetAlert,
  }
}
