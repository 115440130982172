<template>
  <ui-flexbox v-if="url" align="center" justify="center" full-width>
    <NuxtLink :to="url" external target="_blank" class="tx-link">
      <ui-text variant="body3" color="textTertiary">View Transaction</ui-text>
      <ui-icon color="textTertiary" :svg="HyperlinkIcon" size="18" />
    </NuxtLink>
  </ui-flexbox>
</template>
<script setup lang="ts">
import HyperlinkIcon from '@base/assets/img/svg/icon-hyperlink.svg'
const props = defineProps({
  txHash: {
    type: String,
    required: true,
  },
})

const { txHash } = toRefs(props)
const { explorerLink } = useNetwork()

const url = computed(() => {
  if (!txHash.value) return ''
  return `${explorerLink.value}/tx/${txHash.value}`
})
</script>
<style lang="postcss" scoped>
.tx-link {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: max-content;
  color: var(--brand-color-text-tertiary);
  border-radius: 4px;
  &:hover {
    color: var(--brand-color-text-secondary);
    & .ui-text {
      color: var(--brand-color-text-primary);
    }
  }
  &:active {
    color: var(--brand-color-text-primary);
    & .ui-text {
      color: var(--brand-color-text-primary);
    }
  }
  &[disabled='true'] {
    color: var(--brand-color-text-tertiary);
    & .ui-text {
      color: var(--brand-color-text-tertiary);
    }
  }
  &:focus-visible {
    outline: 1px solid var(--brand-color-text-tertiary);
  }
}
</style>
