<template>
  <ul class="tx-token-flow">
    <li v-for="(item, index) in tokenFlow" :key="index">
      <TokenFlowItem :data="item.value" :label="item.label" />
    </li>
  </ul>
</template>
<script setup lang="ts">
import type { TransactionItem, ITokenFlowItem } from '../types'
import TokenFlowItem from './TokenFlowItem.vue'

const props = defineProps({
  data: {
    type: Object as PropType<TransactionItem>,
    default: () => ({}),
  },
})

const { data } = toRefs(props)
const { divDecimals, getOriginalSymbol } = useTokens()

const getTokenFlowItem = (item: ITokenFlowItem) => {
  if (!item.token) return null
  return {
    token: getOriginalSymbol(item.token),
    amount: useTokenNumberFormat(
      divDecimals(item.amount ?? 0, item.token).value,
      {
        token: item.token,
      },
    ),
    usd: useUSDFormat(item.usd ?? 0),
  }
}

const getTokenFlowList = (data: ITokenFlowItem[]) => {
  if (!data.length) return []
  return data
    .map((item) => getTokenFlowItem(item))
    .filter((item) => item !== null) as NonNullable<ITokenFlowItem>[]
}

const tokenFlow = computed(() => {
  return [
    {
      label: 'Out',
      value: getTokenFlowList(data.value.outTokens),
    },
    {
      label: 'In',
      value: getTokenFlowList(data.value.inTokens),
    },
    {
      label: 'Fee',
      value: getTokenFlowList([
        {
          token: data.value.feeToken,
          amount: data.value.fees,
          usd: data.value.feesUSD,
        },
        {
          token: data.value.fee2Token,
          amount: data.value.fees2,
          usd: data.value.fees2USD,
        },
      ]),
    },
  ].filter((item) => !!item.value.length)
})
</script>
<style lang="postcss" scoped>
.tx-token-flow {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;
}
</style>
