<template>
  <el-menu-item
    v-bind="{ ...$attrs, ...elProps }"
    class="ui-menu-item"
    :class="{
      'ui-menu-item--divided': !!props.divided,
    }"
  >
    <template #default>
      <div class="ui-menu-item__content">
        <Icon
          v-if="!!props.startIcon"
          :svg="startIcon"
          size="24"
          color="inherit"
          class="ui-menu-item__icon"
        />
        <slot name="default"></slot>
      </div>
      <Icon
        v-if="!!props.endIcon"
        :svg="endIcon"
        size="24"
        color="inherit"
        class="ui-menu-item__icon"
      />
    </template>
  </el-menu-item>
</template>
<script setup lang="ts">
import { menuItemProps } from 'element-plus'
import Icon from '@base/components/ui/icon'

const props = defineProps({
  ...menuItemProps,
  /**
   * Show divider line on top of the item
   */
  divided: {
    type: Boolean,
    default: false,
  },
  /**
   * Icon component to show in front of the item text
   */
  startIcon: {
    type: Object as PropType<Component>,
    default: null,
  },
  /**
   * Icon component to show at the end of the item text
   */
  endIcon: {
    type: Object as PropType<Component>,
    default: null,
  },
})
const elProps = computed(() => {
  const { startIcon, endIcon, divided, ...otherProps } = props
  return otherProps
})
</script>
<style lang="postcss" scoped>
.ui-menu-item.el-menu-item {
  position: relative;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: space-between;
  height: auto;
  padding: 0.5rem 0.75rem; /* 8px 12px */
  padding-left: 0.75rem !important; /* 12px */
  font-family: var(--font-family-secondary);
  font-size: 1rem; /* 16px */
  font-weight: 600;
  line-height: 1.375rem; /* 22px */
  color: var(--brand-color-text-secondary);
  border-radius: 4.0625rem; /* 65px */

  &.ui-menu-item--divided {
    margin-top: 0.75rem;
    &::before {
      position: absolute;
      top: -0.75rem;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      pointer-events: none;
      content: '';
      background-color: var(--brand-color-text-tertiary);
    }
  }
  & .ui-menu-item__icon {
    color: var(--brand-color-text-tertiary);
    transition: color var(--el-transition-duration);
  }
  &:hover {
    color: var(--brand-color-text-secondary);
    & .ui-menu-item__icon {
      color: var(--brand-color-text-secondary);
    }
  }
  &.is-active,
  &:active {
    color: var(--brand-color-text-secondary);
    & .ui-menu-item__icon {
      color: var(--brand-color-text-primary);
    }
  }
  &.is-disabled {
    color: var(--brand-color-text-tertiary);
    & .ui-menu-item__icon {
      color: var(--brand-color-text-tertiary);
    }
  }
}
.ui-menu-item__content {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
</style>
