import { useENSStore } from '@base/store/ens'

/**
 * @file ENS Composable
 * @module composables/useENS
 * @description Use ENS functions
 */
export function useENS() {
  const { fetchState, fetchResolveNameState } = toRefs(useENSStore())

  /**
   * @description: Lookup address
   * @param address - Wallet address
   */
  const lookupAddress = (address: Ref<string> | string) => {
    return computed(() => fetchState.value(unref(address)).value)
  }

  /**
   * @description: Resolve name
   * @param name - ENS name
   */
  const resolveName = (name: Ref<string> | string) => {
    return computed(() => fetchResolveNameState.value(unref(name)).value)
  }

  return {
    lookupAddress,
    resolveName,
  }
}
