<template>
  <ui-flexbox direction="column" full-width :gap="20">
    <BulletsTable
      v-if="showBulletsTable"
      :rows="data.primaryRows as BulletTableRow[]"
    />
    <ui-flexbox v-else-if="showBulletCards" direction="column" :gap="24">
      <BulletTxItemCard
        v-for="item of data.primaryRows"
        :key="(item as TxSuccessBulletCard).bulletAddress"
        :data="item as unknown as IBulletTxItemCard"
      />
    </ui-flexbox>
    <ui-flexbox
      v-else-if="!!message"
      direction="column"
      align="center"
      justify="center"
      style="padding: 40px 0"
    >
      <ui-text v-for="(text, index) of message" :key="index" variant="body3">
        {{ text }}
      </ui-text>
    </ui-flexbox>
    <ui-flexbox v-else direction="column" full-width :gap="20">
      <ItemRow
        v-for="(row, index) in data.primaryRows as TxSuccessRow[]"
        :key="index"
        :data="row"
        variant="primary"
      />
    </ui-flexbox>
    <ui-flexbox
      direction="column"
      full-width
      :gap="20"
      class="tx-success-info__bg"
    >
      <ItemRow
        v-for="(row, index) in data.secondaryRows"
        :key="index"
        :data="row"
        variant="secondary"
      />
      <ui-text v-if="data.message" color="primary" variant="body3" centered>
        {{ data.message }}
      </ui-text>
      <TxLink v-if="data.txHash" :tx-hash="data.txHash" />
      <ContractLink v-if="data.contract" :contract="data.contract" />
      <NuxtLink
        :to="data.cta.url"
        :target="data.cta.external ? '_blank' : '_self'"
        :external="data.cta.external"
        tabindex="-1"
        @click="closeTxDialog"
      >
        <ui-button size="large" full-width @click="handleClick">
          {{ data.cta.label }}
        </ui-button>
      </NuxtLink>
    </ui-flexbox>
  </ui-flexbox>
</template>
<script setup lang="ts">
import { BulletTxTypeList } from '@base/types/tx-dialog'
import type { BulletTableRow } from '@base/types/bullet'
import BazaarBulletsTable from '@base/components/business/bazaar-bullets-table'
import BulletTxItemCard from '@base/components/business/BulletTxItemCard.vue'
import type { IBulletTxItemCard } from '@base/components/business/BulletTxItemCard.vue'
import type { BazaarBulletsTableRow } from '@base/components/business/bazaar-bullets-table'
import type {
  TxSuccessData,
  TxSuccessRow,
  TxSuccessBulletCard,
} from '@base/types/tx-dialog'
import ItemRow from './ItemRow.vue'
import TxLink from './TxLink.vue'
import ContractLink from './ContractLink.vue'
import BulletsTable from '@base/components/business/bullets-table'
import { TxType } from '@base/types/transaction'

const { closeTxDialog } = useTxDialogStore()
const { data: txDialogData } = toRefs(useTxDialogStore())

const data = computed(() => txDialogData.value as TxSuccessData)

const showBulletsTable = computed(() => {
  if (!data.value?.txType) return false
  return BulletTxTypeList.includes(data.value.txType)
})
const showBulletCards = computed(() => {
  if (!data.value?.txType) return false
  return [TxType.BULLET_BUY, TxType.BULLET_SELL].includes(data.value.txType)
})
const message = computed(() => {
  if (data.value?.txType === TxType.BULLET_CANCEL) {
    return ['The requested BULLET', 'has been off-shelved']
  }
  if (data.value?.txType === TxType.BULLET_UPDATE) {
    return ['The requested BULLET', 'has been updated']
  }
  return ''
})
const route = useRoute()
// handle case when CTA must navigate to the page currently at.
const handleClick = () => {
  if (route.path === data.value.cta.url) {
    closeTxDialog()
  }
}
</script>
<style scoped lang="postcss">
.tx-success-info__bg {
  padding: 20px 14px;
  background: var(--brand-color-secondary-disabled);
  border-radius: 14px;
}
</style>
