<template>
  <ui-steps direction="vertical" :active="data.activeStep">
    <ui-steps-step
      v-for="(step, stepIndex) in data.steps"
      :key="step.title"
      :title="step.title"
    >
      <template #description>
        <StepDescription :step="step" :active="stepIndex === data.activeStep" />
      </template>
    </ui-steps-step>
  </ui-steps>
</template>
<script setup lang="ts">
import { type TxPendingData } from '@base/types/tx-dialog'
import StepDescription from './StepDescription.vue'

const { data: txDialogData } = toRefs(useTxDialogStore())

const data = computed(() => txDialogData.value as TxPendingData)
</script>
