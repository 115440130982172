<template>
  <footer class="footer">
    <div class="footer-inner">
      <div class="footer-info">
        <NuxtLink
          :to="LICENSE_URL"
          external
          target="_blank"
          data-test-id="footer-copyright-link"
          tabindex="-1"
        >
          <Button text>
            <Text
              variant="h6"
              color="textSecondary"
              class="footer-copyright-text"
              data-test-id="footer-copyright-text"
              style="color: inherit"
            >
              © Copyright {{ year }} DeOrderBook
            </Text>
          </Button>
        </NuxtLink>
        <ul class="footer-doc-links" data-test-id="footer-doc-links-list">
          <li v-for="link in DOCUMENT_LINKS" :key="link.title">
            <NuxtLink
              :to="link.url"
              external
              target="_blank"
              tabindex="-1"
              class="footer-doc-link"
            >
              <Button text>
                {{ link.title }}
              </Button>
            </NuxtLink>
          </li>
        </ul>
      </div>
      <ul class="footer-social-links" data-test-id="footer-social-links-list">
        <li v-for="link in SOCIAL_LINKS" :key="link.title">
          <NuxtLink
            :to="link.url"
            external
            :alt="link.title"
            target="_blank"
            tabindex="-1"
          >
            <Icon :svg="link.icon" type="button" size="36" />
          </NuxtLink>
        </li>
      </ul>
    </div>
  </footer>
</template>
<script lang="ts" setup>
import Text from '@base/components/ui/text'
import Button from '@base/components/ui/button'
import Icon from '@base/components/ui/icon'
import { DOCUMENT_LINKS, SOCIAL_LINKS, LICENSE_URL } from './constants'

const year = new Date().getFullYear()
</script>
<style lang="postcss" scoped>
.footer {
  display: flex;
  justify-content: center;
  width: 100%;
}
.footer-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1920px;
  padding: 22px 95px;
}
.footer-info {
  display: flex;
  gap: 2.5rem;
  align-items: center;
}
.footer-doc-links {
  --footer-doc-links-gap: 24px;
  display: flex;
  gap: calc(var(--footer-doc-links-gap) * 2 + 1px);
  align-items: center;
  list-style: none;

  & li {
    position: relative;
    display: flex;
    & a {
      display: flex;
    }
    &:not(:last-child)::after {
      position: absolute;
      top: 50%;
      right: calc(0px - var(--footer-doc-links-gap));
      display: block;
      width: 1px;
      height: 13px;
      content: '';
      background-color: var(--brand-color-text-tertiary);
      border-radius: 1px;
      transform: translateY(-50%);
    }
  }
}
.footer-social-links {
  display: flex;
  list-style: none;
  & li {
    display: flex;
    & a {
      display: flex;
    }
  }
}

@media screen and (max-width: 1279px) {
  .footer-inner {
    padding: 22px 32px;
  }
}

@media screen and (max-width: 767px) {
  .footer-inner {
    flex-direction: column;
    gap: 2.5rem;
    align-items: flex-start;
    padding: 1rem;
  }
  .footer-info {
    flex-direction: column;
    gap: 1.25rem;
    align-items: flex-start;
  }
}
</style>
