<template>
  <client-only>
    <aside
      v-if="isFaucetAlertVisible"
      class="faucet-alert"
      role="button"
      @click="handleAlertClick"
    >
      <p class="faucet-alert__text">
        <span>{{ faucetAlertText.title }}</span>
        <span v-if="isTestnet" class="delimiter">–</span>
        <span v-if="isTestnet">{{ faucetAlertText.description }}</span>
      </p>
      <ui-icon
        :svg="CloseIcon"
        type="button"
        size="24"
        @click="hideFaucetAlert"
      />
    </aside>
  </client-only>
</template>
<script setup lang="ts">
import CloseIcon from '@base/assets/img/svg/icon-close.svg'
const { faucetAlertText } = toRefs(useFaucetStore())
const { isConnected } = useWallet()
const { isTestnet } = useNetwork()

const { hideFaucetAlert, isFaucetAlertVisible } = useFaucetState()

const handleAlertClick = () => {
  if (!isTestnet.value) {
    useNotify({
      type: 'info',
      message:
        'Please connect your wallet on testnet: Sepolia / Scroll sepolia',
    })
    return
  }
  if (!isConnected.value) {
    useNotify({
      type: 'info',
      message: 'Please connect your wallet',
    })
  }
}
</script>
<style scoped lang="postcss">
.faucet-alert {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: var(--faucet-alert-height-mobile);
  padding: 1.25rem 2rem;
  background: var(--brand-color-primary-disabled);
}
.faucet-alert__text {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  font-family: Inter, sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.125rem;
  color: var(--brand-color-primary);
  & .delimiter {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .faucet-alert {
    height: var(--faucet-alert-height-tablet);
  }
}
@media screen and (min-width: 1280px) {
  .faucet-alert {
    height: var(--faucet-alert-height-desktop);
  }
  .faucet-alert__text {
    flex-direction: row;
    gap: 4px;
    align-items: center;
    font-size: 1rem;
    line-height: 1.375rem;
    & .delimiter {
      display: block;
    }
  }
}
</style>
