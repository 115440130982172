import { defineStore } from 'pinia'
import {
  feeDistribution,
  feeDistributionLength,
  bulletDistribution,
  bulletDistributionLength,
  hodlWithdrawFeeDistribution,
  hodlWithdrawFeeDistributionLength,
} from '@deorderbook/sdk/ethereum/distributions'

/**
 * store the distribution contracts
 */
export const useDistributionStore = defineStore('distribution', () => {
  const {
    fetchState: fetchFeeDistribution,
    refreshAllState: refreshAllFeeDistribution,
    getCaches: getFeeDistributionCaches,
  } = useAsyncCache((...args: [number]) => {
    return feeDistribution(args[0]).then((res) => ({
      ...res,
      percentage: String(res.percentage),
    }))
  }, undefined)

  const {
    fetchState: fetchFeeDistributionLength,
    refreshAllState: refreshAllFeeDistributionLength,
    getCaches: getFeeDistributionLengthCaches,
  } = useAsyncCache(() => {
    return feeDistributionLength()
  }, 0)

  const {
    fetchState: fetchBulletDistribution,
    refreshAllState: refreshAllBulletDistribution,
    getCaches: getBulletDistributionCaches,
  } = useAsyncCache((...args: [number]) => {
    return bulletDistribution(args[0]).then((res) => ({
      ...res,
      percentage: String(res.percentage),
    }))
  }, undefined)

  const {
    fetchState: fetchBulletDistributionLength,
    refreshAllState: refreshAllBulletDistributionLength,
    getCaches: getBulletDistributionLengthCaches,
  } = useAsyncCache(() => {
    return bulletDistributionLength()
  }, 0)

  const {
    fetchState: fetchHODLWithdrawFeeDistribution,
    refreshAllState: refreshAllHODLWithdrawFeeDistribution,
    getCaches: getHODLWithdrawFeeDistributionCaches,
  } = useAsyncCache((...args: [number]) => {
    return hodlWithdrawFeeDistribution(args[0]).then((res) => ({
      ...res,
      percentage: String(res.percentage),
    }))
  }, undefined)

  const {
    fetchState: fetchHODLWithdrawFeeDistributionLength,
    refreshAllState: refreshAllHODLWithdrawFeeDistributionLength,
    getCaches: getHODLWithdrawFeeDistributionLengthCaches,
  } = useAsyncCache(() => {
    return hodlWithdrawFeeDistributionLength().then((res) => Number(res))
  }, 0)

  return {
    fetchFeeDistribution,
    refreshAllFeeDistribution,
    getFeeDistributionCaches,
    fetchFeeDistributionLength,
    refreshAllFeeDistributionLength,
    getFeeDistributionLengthCaches,
    fetchBulletDistribution,
    refreshAllBulletDistribution,
    getBulletDistributionCaches,
    fetchBulletDistributionLength,
    refreshAllBulletDistributionLength,
    getBulletDistributionLengthCaches,
    fetchHODLWithdrawFeeDistribution,
    refreshAllHODLWithdrawFeeDistribution,
    getHODLWithdrawFeeDistributionCaches,
    fetchHODLWithdrawFeeDistributionLength,
    refreshAllHODLWithdrawFeeDistributionLength,
    getHODLWithdrawFeeDistributionLengthCaches,
  }
})
