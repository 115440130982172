<template>
  <el-menu
    v-bind="{ ...$attrs, ...props }"
    class="ui-menu"
    popper-effect="dark"
  >
    <slot></slot>
  </el-menu>
</template>
<script setup lang="ts">
import { menuProps } from 'element-plus'

const props = defineProps({
  ...menuProps,
})
</script>
<style lang="postcss" scoped>
.ui-menu.el-menu {
  --el-menu-text-color: var(--brand-color-text-secondary);
  --el-menu-hover-text-color: var(--brand-color-text-secondary);
  --el-menu-bg-color: transparent;
  --el-menu-hover-bg-color: var(--brand-color-base-tertiary);

  display: flex;
  flex-direction: row;
  gap: 0.75rem; /* 12px */
  font-family: var(--font-family-secondary);
  border: none;

  &.el-menu--vertical {
    flex-direction: column;
  }
}
</style>
