<template>
  <el-alert
    v-bind="{ ...$attrs, ...props }"
    class="ui-alert"
    effect="dark"
    :show-icon="!!props.showIcon && props.type !== 'error'"
  >
    <template #title>
      <BellIcon
        v-if="props.type === 'error' && !!props.showIcon"
        class="ui-alert__icon"
      />
      <p class="el-alert__title">{{ props.title }}</p>
    </template>
  </el-alert>
</template>
<script setup lang="ts">
import { alertProps } from 'element-plus'
import BellIcon from '@base/assets/img/svg/bell.svg'

const props = defineProps({
  ...alertProps,
})
</script>
<style lang="postcss" scoped>
.ui-alert.el-alert {
  --ui-alert-padding: 1.25rem; /* 20px */

  --ui-alert-title-font-size: 1.125rem; /* 18px */
  --ui-alert-title-font-weight: 400;
  --ui-alert-title-line-height: 1.5rem; /* 24px */

  --el-alert-padding: var(--ui-alert-padding);
  --el-alert-border-radius-base: 0px;
  --el-alert-title-font-size: var(--ui-alert-title-font-size);
  --el-alert-description-font-size: 0.75rem; /* 12px */
  --el-alert-close-font-size: 1.5rem; /* 24px */
  --el-alert-icon-size: 1.5rem; /* 24px */
  --el-alert-icon-large-size: 1.5rem;

  --ui-alert-background-color: transparent;
  --ui-alert-border-color: transparent;
  --ui-alert-text-color: var(--brand-color-text-primary);

  gap: 1rem; /* 16px */
  padding: var(--el-alert-padding);
  padding-right: calc(
    var(--ui-alert-padding) + var(--el-alert-close-font-size)
  );
  color: var(--ui-alert-text-color);
  background-color: var(--ui-alert-background-color);
  border: 1px solid var(--ui-alert-text-color);
  border-radius: var(--el-alert-border-radius-base);
}
.ui-alert.el-alert:deep(.el-alert__icon) {
  margin-right: 0;
}
.ui-alert.el-alert:deep(.ui-alert__icon) {
  position: absolute;
  top: 50%;
  left: var(--ui-alert-padding);
  margin: 0;
  font-size: var(--el-alert-icon-size);
  transform: translateY(-50%);
}
.ui-alert.el-alert:deep(.el-alert__title) {
  display: block;
  font-family: var(--font-family-primary);
  font-size: var(--ui-alert-title-font-size);
  font-weight: var(--ui-alert-title-font-weight);
  line-height: var(--ui-alert-title-line-height);
}
.ui-alert.el-alert:deep(.el-alert__description) {
  font-family: var(--font-family-primary);
  color: var(--ui-alert-text-color);
}
.ui-alert.el-alert:deep(.el-alert__content) {
  display: block;
  height: auto;
  padding: 0;
}
.ui-alert.el-alert.el-alert--error:deep(.el-alert__content) {
  padding-left: calc(var(--el-alert-icon-size) + 1rem);
}
.ui-alert.el-alert:deep(.el-alert__close-btn.el-icon svg) {
  /* This way inner <path/> will be 12px in size */
  width: 1.25rem; /* 20px  */
  height: 1.25rem; /* 20px */
}
.ui-alert.el-alert:deep(.el-alert__close-btn) {
  top: 50%;
  right: var(--ui-alert-padding);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ui-alert-text-color);
  transform: translateY(-50%);
}
.ui-alert.el-alert.el-alert--success.is-light,
.ui-alert.el-alert.el-alert--success.is-dark {
  --ui-alert-text-color: var(--brand-color-success);
  --ui-alert-background-color: rgba(var(--brand-color-success-rgb), 0.1);
}
.ui-alert.el-alert.el-alert--error.is-light,
.ui-alert.el-alert.el-alert--error.is-dark {
  --ui-alert-text-color: var(--brand-color-error);
  --ui-alert-background-color: rgba(var(--brand-color-error-rgb), 0.1);
}
.ui-alert.el-alert.el-alert--warning.is-light,
.ui-alert.el-alert.el-alert--warning.is-dark {
  --ui-alert-text-color: var(--el-color-warning);
}
.ui-alert.el-alert.el-alert--info.is-light,
.ui-alert.el-alert.el-alert--info.is-dark {
  --ui-alert-text-color: var(--el-color-info);
}
</style>
