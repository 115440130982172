<template>
  <div class="ui-header__actions">
    <NetworkSelect v-if="appSettings.showNetworkSelect" />
    <div
      v-if="appSettings.showFaucetInfo && isTestnet"
      class="ui-header__faucet-info"
    >
      <FaucetInfo />
    </div>
    <div v-if="appSettings?.showGasInfo" class="ui-header__gas-info">
      <GasInfo />
    </div>
    <AccountButton v-if="appSettings?.showConnectWallet" />
    <HeaderMenu v-if="appSettings.showMenu" :app="app" />
    <nuxt-link
      v-if="appSettings.showLaunchApp"
      :to="`${DOB_EXTERNAL_LINKS_MAP.APP.url}/pools`"
      target="_blank"
      tabindex="-1"
    >
      <ui-button>Launch App</ui-button>
    </nuxt-link>
  </div>
</template>
<script lang="ts" setup>
import type { DeOrderBookApp } from '@base/types/app'
import AccountButton from './AccountButton.vue'
import GasInfo from './GasInfo.vue'
import FaucetInfo from './FaucetInfo.vue'
import HeaderMenu from './header-menu/HeaderMenu.vue'
import NetworkSelect from './network-select'
import { DOB_APPS_MAP, DOB_EXTERNAL_LINKS_MAP } from '@base/utils/constants'
const props = defineProps({
  app: {
    type: String as PropType<DeOrderBookApp>,
    required: true,
  },
})

const { app } = toRefs(props)
const { isTestnet } = useNetwork()

const appSettings = computed(() => {
  return DOB_APPS_MAP[app.value] || {}
})
</script>
<style lang="postcss" scoped>
.ui-header__actions {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  justify-content: flex-end;
  & .ui-header__gas-info,
  & .ui-header__faucet-info {
    display: flex;
  }
}
@media screen and (max-width: 767px) {
  .ui-header__actions {
    gap: 0.75rem;
    & .ui-header__gas-info,
    & .ui-header__faucet-info {
      display: none;
    }
  }
}
</style>
