import { TxDialogState } from '@base/types/tx-dialog'
import type { ContractTransactionResponse } from 'ethers'
import { getTestTokens } from '@deorderbook/sdk/ethereum/test_token_pool'

export function useClaimFaucetTokens() {
  const loading = ref(false)
  const setLoading = (v: boolean) => (loading.value = v)

  const { updateTxDialog } = useTxDialogStore()
  const { fetchLastTimestamp } = useFaucetStore()

  const handleTxResponse = async (tx: ContractTransactionResponse) => {
    const { waitTx } = useWaitTx(tx)
    const result = await waitTx()
    if (!result || result.status !== 1) return null
    return result.hash
  }

  const onSubmit = async () => {
    setLoading(true)
    updateTxDialog(TxDialogState.PENDING, {
      description: 'Test Tokens',
      steps: [
        {
          title: 'Send Test Tokens',
          description: [],
        },
      ],
      activeStep: 0,
    })
    try {
      const tx = await getTestTokens()

      const txHash = await handleTxResponse(tx)
      if (!txHash) {
        updateTxDialog(TxDialogState.HIDDEN)
        return
      }

      fetchLastTimestamp()
      useNotify({
        type: 'success',
        message: 'Received test token',
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.error(e)
      useNotify({
        type: 'error',
        message:
          (e?.reason
            ? `${e.reason.charAt(0).toUpperCase()}${e.reason.slice(1)}.`
            : 'Failed to receive test token;') + ' Please try again later.',
      })
    } finally {
      setLoading(false)
      updateTxDialog(TxDialogState.HIDDEN)
    }
  }

  return {
    onSubmit,
    loading,
  }
}
