import { defineStore } from 'pinia'
import { ChainId } from '@deorderbook/contracts'

/**
 * @description: ENS store
 * @module store/ens
 * @export useENSStore
 */
export const useENSStore = defineStore('ens', () => {
  const { defaultProvider } = useChains()

  const { fetchState, refreshAllState } = useActionCache(
    (...args) => {
      if (!defaultProvider.value) return Promise.resolve('')
      return defaultProvider.value.lookupAddress(args[0])
    },
    '',
    {
      interval: Number.MAX_SAFE_INTEGER,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (e: any) => {
        if (!e.message.includes(ChainId.SCROLL_SEPOLIA)) {
          console.error(e)
        }
      },
    },
  )

  const {
    fetchState: fetchResolveNameState,
    refreshAllState: refreshAllResolveNameState,
  } = useActionCache(
    (...args) => {
      if (!defaultProvider.value) return Promise.resolve('')
      return defaultProvider.value.resolveName(args[0])
    },
    '',
    {
      interval: Number.MAX_SAFE_INTEGER,
    },
  )

  return {
    fetchState,
    refreshAllState,
    fetchResolveNameState,
    refreshAllResolveNameState,
  }
})
