<template>
  <Popover
    :show-arrow="false"
    placement="bottom-end"
    automatic-dropdown
    :disabled="!data.length"
    :width="160"
  >
    <template #reference>
      <div class="gas-info" tabindex="0">
        <Icon :svg="GasIcon" color="inherit" />
        <Text color="inherit" variant="body3">
          {{ gas }}
        </Text>
      </div>
    </template>
    <ul v-if="data?.length" class="gas-info-list">
      <li v-for="item in data" :key="item.type" class="gas-info-item">
        <Text variant="body5" color="textTertiary">{{ item.label }}</Text>
        <Text variant="body5" color="textTertiary">
          {{ item.value }} {{ item.unit }}
        </Text>
      </li>
    </ul>
  </Popover>
</template>
<script lang="ts" setup>
import Popover from '@base/components/ui/popover'
import Icon from '@base/components/ui/icon'
import Text from '@base/components/ui/text'
import GasIcon from '@base/assets/img/svg/icon-gas.svg'

const { data: gasInfo } = useGasInfo()

const roundToSignificantDigits = (value?: string, digits = 3) => {
  if (isNaN(Number(value))) return ''
  return Number(value)
    .toPrecision(digits) // Round to significant digits
    .replace(/\.?0+$/, '') // Remove trailing zeros
}

const data = computed(() =>
  gasInfo.value.map((item) => ({
    ...item,
    value: roundToSignificantDigits(item.value),
  })),
)

const gas = computed(() => {
  if (!data.value?.length) return ''
  return data.value.find((item) => item.type === GasType.AVG)?.value
})
</script>
<style lang="postcss" scoped>
.gas-info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  min-width: 50px;
  color: var(--brand-color-text-tertiary);
  &:focus-visible {
    color: var(--brand-color-text-primary);
    outline: none;
  }
}
.gas-info-list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.gas-info-item {
  display: flex;
  gap: 1.25rem;
  justify-content: space-between;
}
</style>
