<template>
  <header
    class="ui-header-wrapper"
    :class="{
      '--bg': scrollY > 0 && app !== 'home',
    }"
  >
    <div class="ui-header">
      <HeaderNavLogo :app="app" />
      <div class="ui-header-nav-links__wrapper">
        <HeaderNavLinks :app="app" />
      </div>
      <HeaderActions v-bind="{ ...props }" />
    </div>
  </header>
</template>
<script lang="ts" setup>
import HeaderActions from './header-actions/HeaderActions.vue'
import HeaderNavLogo from './HeaderNavLogo.vue'
import HeaderNavLinks from './HeaderNavLinks.vue'
import type { DeOrderBookApp } from '@base/types/app'
import { useWindowScroll } from '@vueuse/core'

const props = defineProps({
  app: {
    type: String as PropType<DeOrderBookApp>,
    default: '',
  },
})

const { y: scrollY } = useWindowScroll()
</script>
<style lang="postcss" scoped>
.ui-header-wrapper {
  position: fixed;
  top: 0;
  z-index: 2001;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: background 0.3s;
  &.--bg {
    background: var(--brand-color-base-primary);
  }
}
.ui-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1920px;
  height: var(--layout-header-height-desktop);
  padding: 15px 96px;
}
@media screen and (max-width: 1279px) {
  .ui-header {
    height: var(--layout-header-height-mobile);
    padding: 15px 32px;
  }
  .ui-header-nav-links__wrapper {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .ui-header {
    padding: 15px 16px;
  }
}
</style>
