<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <ui-flexbox
    align="stretch"
    justify="center"
    full-width
    :gap="4"
    class="onboarding-carousel"
  >
    <template v-if="data">
      <div
        :style="{ visibility: isStart ? 'hidden' : 'visible' }"
        style="display: flex; align-items: center"
      >
        <ui-icon
          type="button"
          :svg="IconLeft"
          size="24"
          @click="handlePrevStep"
        />
      </div>
      <ui-flexbox
        direction="column"
        align="center"
        justify="space-between"
        :gap="20"
        full-width
      >
        <ui-flexbox
          direction="column"
          align="center"
          justify="center"
          :gap="20"
          full-width
          style="flex: 1"
        >
          <img :src="data.img" alt="Onboarding" class="onboarding-step-img" />
        </ui-flexbox>
        <ui-flexbox
          direction="column"
          align="center"
          justify="flex-start"
          :gap="20"
          full-width
        >
          <ui-text
            variant="body3"
            color="primary"
            centered
            v-html="data.text"
          ></ui-text>
          <ui-flexbox align="center" justify="center">
            <div
              v-for="(item, index) in activeStep"
              :key="index"
              style="display: flex; padding: 10px"
            >
              <span
                class="step-circle"
                :class="{ '--active': index === carouselStep }"
              />
            </div>
          </ui-flexbox>
        </ui-flexbox>
      </ui-flexbox>
      <div
        :style="{ visibility: isEnd ? 'hidden' : 'visible' }"
        style="display: flex; align-items: center"
      >
        <ui-icon
          type="button"
          :svg="IconRight"
          size="24"
          @click="handleNextStep"
        />
      </div>
    </template>
  </ui-flexbox>
</template>
<script setup lang="ts">
import Step_1_1 from '@/assets/img/onboarding/1-1.png'
import Step_1_2 from '@/assets/img/onboarding/1-2.png'
import Step_1_3 from '@/assets/img/onboarding/1-3.png'
import Step_2_1 from '@/assets/img/onboarding/2-1.png'
import Step_2_2 from '@/assets/img/onboarding/2-2.png'
import Step_3_1 from '@/assets/img/onboarding/3-1.png'
import Step_3_2 from '@/assets/img/onboarding/3-2.png'
import Step_3_3 from '@/assets/img/onboarding/3-3.png'

import Step_1_1_Scroll from '@/assets/img/onboarding/scroll/1-1-scroll.png'
import Step_1_2_Scroll from '@/assets/img/onboarding/scroll/1-2-scroll.png'
import Step_1_3_Scroll from '@/assets/img/onboarding/scroll/1-3-scroll.png'
import Step_2_2_Scroll from '@/assets/img/onboarding/scroll/2-2-scroll.png'
import Step_3_1_Scroll from '@/assets/img/onboarding/scroll/3-1-scroll.png'
import Step_3_2_Scroll from '@/assets/img/onboarding/scroll/3-2-scroll.png'

import IconLeft from '@base/assets/img/svg/icon-left-direction.svg'
import IconRight from '@base/assets/img/svg/icon-right-direction.svg'

const props = defineProps({
  activeStepIndex: {
    type: Number,
    required: true,
  },
})
const { activeStepIndex } = toRefs(props)
const { getOriginalSymbol } = useTokens()
const { dobSymbol, lockPeriodString } = useLockInfo()
const {
  bulletDistributionState,
  bulletDistributionLengthReady,
  refreshBulletDistributionLength,
} = useBulletDistribution()

onMounted(() => {
  if (!bulletDistributionLengthReady.value || !bulletDistributionState.value) {
    refreshBulletDistributionLength()
  }
})
const { isScrollNetwork } = useNetwork()

const steps = computed(() => {
  if (isScrollNetwork.value) {
    return [
      [Step_1_1_Scroll, Step_1_2_Scroll, Step_1_3_Scroll],
      [Step_2_1, Step_2_2_Scroll],
      [Step_3_1_Scroll, Step_3_2_Scroll, Step_3_3],
    ]
  }
  return [
    [Step_1_1, Step_1_2, Step_1_3],
    [Step_2_1, Step_2_2],
    [Step_3_1, Step_3_2, Step_3_3],
  ]
})

const STEPS_DATA = computed((): { text: string; img: string }[][] => [
  [
    {
      text: `Mint your ${getOriginalSymbol('sellToken')} and USDC to HODL first. We’re set to start earning ${dobSymbol.value} tokens.`,
      img: steps.value[0][0],
    },
    {
      text: `For a safe option, our simple HODL staking pools are ready.<br/>Staking and earn ${dobSymbol.value}!`,
      img: steps.value[0][1],
    },
    {
      text: `To earn faster, place DeOrders based on your expected Bitcoin price.<br/>You can earn ${dobSymbol.value} with high vAPR.`,
      img: steps.value[0][2],
    },
  ],
  [
    {
      text: `Claim your ${dobSymbol.value} from HODL pools and DeOrder pools to be ready for maximum earning.`,
      img: steps.value[1][0],
    },
    {
      text: `Lock ${dobSymbol.value} for ${lockPeriodString.value} to receive HODL tokens as real yield from fee sharing.`,
      img: steps.value[1][1],
    },
  ],
  [
    {
      text: `After DeOrder, equal SNIPER and BULLET tokens generated; BULLET returns to ${dobSymbol.value} lockers.`,
      img: steps.value[2][0],
    },
    {
      text: 'With BULLET, you have the power to exercise at the strike price, giving you the chance to buy or sell at an incredible price!',
      img: steps.value[2][1],
    },
    {
      text: 'BULLET generated by DeOrder.<br/>More DeOrder, More chances - <br/>Let’s start to earn!',
      img: steps.value[2][2],
    },
  ],
])
const carouselStep = ref(0)
const activeStep = computed(() => STEPS_DATA.value.at(activeStepIndex.value))
const data = computed(() => activeStep.value?.at(carouselStep.value))
const isStart = computed(
  () => carouselStep.value === 0 && activeStepIndex.value === 0,
)
const isEnd = computed(
  () =>
    carouselStep.value === (activeStep.value || []).length - 1 &&
    activeStepIndex.value === STEPS_DATA.value.length - 1,
)

const handleNextStep = () => {
  if (activeStep.value === undefined) return
  if (carouselStep.value >= activeStep.value.length - 1) {
    emits('update:activeStep', activeStepIndex.value + 1)
    return
  }
  carouselStep.value += 1
}

const handlePrevStep = () => {
  if (carouselStep.value <= 0) {
    emits('update:activeStep', activeStepIndex.value - 1)
    return
  }
  carouselStep.value -= 1
}

watch(
  () => activeStepIndex.value,
  () => {
    carouselStep.value = 0
  },
  { immediate: true },
)

const emits = defineEmits(['update:activeStep'])
</script>
<style scoped lang="postcss">
.onboarding-carousel {
  width: 100%;
  padding: 20px;
  background: rgba(121, 121, 121, 0.2);
  border-radius: 10px;
}
.onboarding-step-img {
  width: 100%;
  max-width: 258px;
  height: auto;
}
.step-circle {
  width: 11px;
  height: 11px;
  background-color: rgba(121, 121, 121, 0.2);
  border-radius: 50%;
  &.--active {
    background-color: var(--brand-color-primary);
  }
}

@media only screen and (min-width: 768px) {
  .onboarding-carousel {
    width: 50%;
    border-radius: 0px 24px 24px 0px;
  }
}
</style>
