<template>
  <ui-text variant="h5" :color="header.titleColor">
    {{ header.title }}
  </ui-text>
  <ui-text variant="body4" color="textTertiary" component="p">
    {{ header.subtitle }}
  </ui-text>
</template>
<script setup lang="ts">
import { TxDialogState } from '@base/types/tx-dialog'
import type { TextColor } from '@base/components/ui/text'

const { data, state } = toRefs(useTxDialogStore())

const TX_TITLE_MAP: Record<
  TxDialogState,
  { title: string; titleColor: TextColor }
> = {
  [TxDialogState.PENDING]: {
    title: 'Transaction',
    titleColor: 'textPrimary',
  },
  [TxDialogState.SUCCESS]: {
    title: 'Success',
    titleColor: 'textPrimary',
  },
  [TxDialogState.FAILURE]: {
    title: 'Error',
    titleColor: 'error',
  },
  [TxDialogState.HIDDEN]: {
    title: 'Transaction',
    titleColor: 'textPrimary',
  },
}

const header = computed(() => {
  const { title, titleColor } = TX_TITLE_MAP[state.value]

  const subtitle =
    state.value === TxDialogState.FAILURE || !data.value?.description
      ? undefined
      : data.value.description

  return { title, titleColor, subtitle }
})
</script>
