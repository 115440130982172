import { defineStore } from 'pinia'
import type { GasFeeInfo } from '@base/types/fee'
import { gql } from '@urql/core'

const GET_GAS_FEE = gql`
  query getDerivedGasInfo {
    derived_gas_info {
      key
      value
    }
  }
`

type DerivedGasInfoType =
  | 'LastBlock'
  | 'SafeGasPrice'
  | 'ProposeGasPrice'
  | 'FastGasPrice'
  | 'suggestBaseFee'
  | 'gasUsedRatio'

interface DerivedGasInfoResponse {
  derived_gas_info: {
    key: DerivedGasInfoType
    value: string
  }[]
}

export const useGasFeeStore = defineStore('fee', () => {
  const { mainnetClient } = useBackendClient()

  const { execute, data, status } = useCacheableAsyncData(async () => {
    const res = await mainnetClient.value.query<DerivedGasInfoResponse>(
      GET_GAS_FEE,
      {},
    )
    if (res.error) {
      throw res.error
    }

    const gasFeeData = res.data?.derived_gas_info
    if (!gasFeeData) {
      throw new Error('Failed to fetch gas fee data')
    }

    const data: GasFeeInfo = {
      averageGasPrice:
        gasFeeData.find((item) => item.key === 'SafeGasPrice')?.value || '0',
      safeLowGasPrice:
        gasFeeData.find((item) => item.key === 'ProposeGasPrice')?.value || '0',
      fastGasPrice:
        gasFeeData.find((item) => item.key === 'FastGasPrice')?.value || '0',
    }

    return data
  })

  const loading = computed(() => status.value === 'pending')

  onWalletReady(
    () => {
      execute()
    },
    { status: 'setup' },
  )

  if (getCurrentInstance()) {
    onMounted(() => {
      const intervalId = setInterval(() => {
        execute()
      }, 60000) // 1 minute

      return () => {
        clearInterval(intervalId)
      }
    })
  }

  return {
    loading,
    fees: data,
    fetchGasfee: execute,
  }
})
