<template>
  <Transition>
    <div v-if="true" class="page--flex">
      <FaucetAlert />
      <Header />
      <main>
        <Alerts />
        <slot></slot>
      </main>
      <FooterContainer />
      <TxDialog />
      <Onboarding />
      <FaucetDialog />
    </div>
  </Transition>
</template>
<script setup lang="ts">
import Header from '@/components/layout/Header.vue'
import { FooterContainer } from '@base/components/layout/footer'
import Alerts from '@/components/business/alerts'
import TxDialog from '@base/components/business/tx-dialog'
import Onboarding from '@/components/others/onboarding'
import FaucetAlert from '@base/components/business/faucet/FaucetAlert.vue'
import FaucetDialog from '@base/components/business/faucet/FaucetDialog.vue'

useLayout()
useDevPasswordAuth()
</script>
