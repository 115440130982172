import { Big } from 'big.js'
import { defineStore } from 'pinia'
import {
  getTokenTransferAmountsMap,
  getLastWithdrawTimestamp,
} from '@deorderbook/sdk/ethereum/test_token_pool'

export const useFaucetStore = defineStore('faucet', () => {
  const { address } = useAccount()
  const { isTestnet } = useNetwork()
  const { getTokenBy, divDecimals, getOriginalSymbol } = useTokens()

  const {
    execute: fetchTokens,
    data: tokenTransferAmountsMap,
    status,
  } = useCacheableAsyncData(getTokenTransferAmountsMap)

  const fetchTokenTransferAmounts = async () => {
    if (!isTestnet.value) return
    await fetchTokens()
  }

  const tokens = computed((): { token: string; amount: number }[] => {
    if (
      !tokenTransferAmountsMap.value ||
      !(tokenTransferAmountsMap.value instanceof Map)
    )
      return []
    const res: { token: string; amount: number }[] = []
    for (const [
      tokenAddress,
      value,
    ] of tokenTransferAmountsMap.value.entries()) {
      const token = getTokenBy({ address: tokenAddress })?.symbol
      const amountRaw = Number(value || '0')
      if (!token || amountRaw === 0) continue
      const amount = Big(divDecimals(amountRaw, token).value).toNumber()
      res.push({
        token: getOriginalSymbol(token),
        amount,
      })
    }
    return res
  })

  const faucetAlertText = computed(() => {
    const tokenAmountsString = tokens.value
      .map(({ token, amount }) => `${amount} ${token}`)
      .join(' and ')
    return {
      title: 'Testnet Faucet Now Available!',
      description: `Play with ${tokenAmountsString} Tokens on the Testnet`,
    }
  })

  const userLastWithdrawTimestamp = ref(0)
  const userLastWithdrawTimestampStatus = ref<
    'idle' | 'pending' | 'success' | 'error'
  >('idle')
  async function fetchLastTimestamp() {
    if (!address.value || !isTestnet.value) return
    try {
      userLastWithdrawTimestampStatus.value = 'pending'
      const bigNum = await getLastWithdrawTimestamp(address.value)
      userLastWithdrawTimestamp.value = Number(bigNum || '0') * 1000
      userLastWithdrawTimestampStatus.value = 'success'
    } catch (e) {
      console.error(
        `FAUCET: failed to fetch user's last withdraw timestamp:\n`,
        e,
      )
      userLastWithdrawTimestampStatus.value = 'error'
    }
  }

  onWalletReady(
    () => {
      fetchTokenTransferAmounts()
    },
    { status: 'setup' },
  )

  watch(() => address.value, fetchLastTimestamp, { immediate: true })

  return {
    faucetAlertText,
    tokens,
    tokensLoading: computed(() => status.value === 'pending'),
    userLastWithdrawTimestamp,
    userLastWithdrawTimestampStatus,
    fetchLastTimestamp,
    fetchTokenTransferAmounts,
  }
})
