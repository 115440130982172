import type { ComputedRef, Ref, UnwrapRef } from 'vue'

export interface UseActionCacheOptions {
  /** Interface cache duration, default is 15000 */
  interval: number
  onError?: (e: unknown) => void
}
export interface CacheMapItem<Data> {
  timestamp: number
  value:
    | {
        state: Data
        isReady: Ref<boolean>
        isLoading: Ref<boolean>
        error: Ref<unknown>
        refresh: () => Promise<Data>
      }
    | undefined
}

// TODO: Type optimization

/**
 * @deprecated please use `useCacheableAsyncData` instead.
 * @description Cache the data of interface calls in the store, different parameters of interface calls will be cached separately. Return Computed value through fetchState
 * @export
 * @param {(...args: any[]) => Promise<any>} cb Interface callback
 * @param {*} initialState Initial value
 * @param {*} [options={} as UseActionCacheOptions] extends from useAsyncState
 * @return {*}
 */
export function useActionCache<Data>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cb: (...args: any[]) => Promise<Data>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any,
  options = {} as UseActionCacheOptions,
) {
  const cacheMap = reactive(new Map<string, CacheMapItem<Data>>())

  // [ Options ]
  const { interval = 15000, ...useAsyncStateOptions } = options
  const defaultUseAsyncStateOptions = {
    onError: (e: unknown) => {
      console.error(e)
    },
  }

  /**
   * @description Call the interface, return the cached UseAsyncStateReturn type data, if the call interval is greater than interval, it will be retrieved again, using the refresh in the return value can force the data to be refreshed
   * @param {unknown[]} [args] Interface parameters
   * @return {*} The return value type refers to CacheMapItem, including state, isReady, isLoading, error, refresh
   */
  const fetchState = (...args: unknown[]) => {
    const state = cacheMap.get(generateKey(args))

    if (state !== undefined && Date.now() < state.timestamp + interval) {
      // Return cached value
      return computed(() => state.value!)
    }
    // Call the interface
    return setState(
      args,
      useAsyncState(() => cb(...args), initialState, {
        ...defaultUseAsyncStateOptions,
        ...useAsyncStateOptions,
      }),
    )
  }

  /**
   * @description Get data from cache using args as key
   * @param {unknown[]} [args] Parameters passed to the interface
   * @notice When fetchState is not called, it returns Computed<undefined>
   */
  const getState = (...args: unknown[]) => {
    const key = generateKey(args)
    // if (cacheMap.has(key)) {
    return computed(() => cacheMap.get(key)?.value)
    // }
    // else {
    //   // Set initial value
    //   return setState(params, initialState, 0)
    // }
  }

  /**
   * @description Set the returned value of the interface using params as key
   * @param {(unknown[] | undefined)} params Interface parameters
   * @param {unknown} value Interface return value
   * @param {number} [timestamp=Date.now()] Time stamp of interface call, used to compare with interval to control whether to retrieve again
   * @return {*} Return cached value
   */
  const setState = (
    params: unknown[] | undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    timestamp: number = Date.now(),
  ) => {
    const { state, isReady, isLoading, error, execute } = value
    cacheMap.set(generateKey(params), {
      timestamp,
      value: {
        state,
        isReady,
        isLoading,
        error,
        // refresh will call the interface again and update the cached timestamp.
        refresh: (force = true) =>
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          execute().then((res: any) => {
            setTimeout(() => {
              const state = getState(...(params || []))

              cacheMap.set(generateKey(params), {
                timestamp: Date.now(),
                value: state.value as NonNullable<typeof state.value>,
              })
            })
            return res
          }),
      },
    })
    const newState = getState(...(params || []))
    return newState as ComputedRef<NonNullable<typeof newState.value>>
  }

  /**
   * @description Refresh all data in cacheMap
   */
  function refreshAllState() {
    cacheMap.forEach((state) => {
      state.value?.refresh?.()
    })
  }

  /**
   * @description Generate Map key through params
   * @param {unknown[]} [params] Interface parameters, optional
   * @return { string } key
   */
  const generateKey = (params?: unknown[]) => {
    return 'key_' + JSON.stringify(params)
  }

  /**
   * @description Get all values in cacheMap and return as an array
   */
  const getCaches = () => {
    return computed(() => {
      const caches = [] as (UnwrapRef<Data> | undefined)[]
      cacheMap.forEach((x) => {
        caches.push(x.value?.state)
      })
      return caches
    })
  }

  return {
    /** @deprecated please use `useCacheableAsyncData` instead */
    cacheMap,
    /** @deprecated please use `useCacheableAsyncData` instead */
    fetchState,
    /** @deprecated please use `useCacheableAsyncData` instead */
    getState,
    /** @deprecated please use `useCacheableAsyncData` instead */
    refreshAllState,
    /** @deprecated please use `useCacheableAsyncData` instead */
    getCaches,
    generateKey,
  }
}
