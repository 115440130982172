export default function useBulletClaimAlert() {
  const { nextRaffleCountdown } = useLockInfo()
  const { isRewardClaimable } = useBulletReward()

  const isHidden = useSessionStorage('bullet-claim-alert-hidden', false)

  const onClose = (e: MouseEvent) => {
    e.stopPropagation()
    isHidden.value = true
  }

  const visible = computed(() => isRewardClaimable.value && !isHidden.value)

  const message = computed(
    () =>
      `BULLET claim starts now! Remaining time ${nextRaffleCountdown.value}`,
  )

  return {
    visible,
    message,
    onClose,
  }
}
