<template>
  <ui-dialog
    :model-value="modelValue"
    :width="704"
    :before-close="onBeforeClose"
    :center="false"
    class="onboarding-dialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @update:model-value="updateModelValue"
  >
    <template #header></template>
    <ui-gridbox column="repeat(2, 1fr)">
      <ui-flexbox
        direction="column"
        align="flex-start"
        :gap="60"
        style="padding: 36px"
      >
        <ui-flexbox direction="column">
          <ui-text variant="h4">
            Welcome to
            <br />
            DeOrderBook
          </ui-text>
          <ui-text variant="body4" color="textTertiary">
            Maximizing your asset with DeOrderBook
          </ui-text>
        </ui-flexbox>
        <ui-flexbox direction="column" align="flex-start" :gap="20">
          <ui-steps direction="vertical" :active="activeStep">
            <ui-steps-step v-for="step of steps" :key="step" :title="step" />
          </ui-steps>
          <ui-checkbox
            :model-value="notShow"
            @update:model-value="updateCheckboxValue"
          >
            Do not show again
          </ui-checkbox>
        </ui-flexbox>
      </ui-flexbox>
      <OnboardingCarousel
        :active-step-index="activeStep"
        @update:active-step="updateActiveStep"
      />
    </ui-gridbox>
  </ui-dialog>
</template>
<script setup lang="ts">
import OnboardingCarousel from './OnboardingCarousel.vue'
defineProps({
  notShow: {
    type: Boolean,
    required: true,
  },
  steps: {
    type: Array as PropType<string[]>,
    required: true,
  },
})
const activeStep = ref(0)

const onBeforeClose = (done: () => void) => {
  emits('beforeClose')
  done()
}

const updateActiveStep = (value: number) => {
  activeStep.value = value
}

const modelValue = defineModel<boolean>({ required: true })
const emits = defineEmits([
  'update:modelValue',
  'update:notShow',
  'beforeClose',
])
function updateModelValue(value: boolean) {
  emits('update:modelValue', value)
}
function updateCheckboxValue(value: boolean) {
  emits('update:notShow', value)
}
</script>
<style lang="postcss">
.onboarding-dialog.ui-dialog.el-dialog {
  gap: 0;
  padding: 0;
}
</style>
