<template>
  <div class="tx-list-item" :class="{ '--first': txData.isFirstTxOfDay }">
    <ui-text
      v-if="txData.isFirstTxOfDay"
      variant="body2"
      color="textTertiary"
      class="tx-list__group-header"
    >
      {{ dayjs(txData.date).format('D MMMM YYYY') }}
    </ui-text>
    <NuxtLink external :to="url" target="_blank" class="tx-list-item__body">
      <div class="tx-list-item__header">
        <ui-text
          variant="body3"
          color="textSecondary"
          class="tx-list-item__time"
        >
          {{ dayjs(txData.date).format('h:mm A') }}
        </ui-text>
        <ExplorerLink
          type="tx"
          disabled
          :hash="txData.txHash"
          tabindex="-1"
          class="tx-explorer-link --header"
          :text-variant="explorerLinkVariant"
        />
      </div>
      <div class="tx-list-item__info">
        <div class="tx-list-item__info__header">
          <div class="tx-list-item__info__label">
            <ui-text variant="h5" color="textSecondary">
              {{ txData.title }}
            </ui-text>
            <ui-divider
              direction="vertical"
              :spacing="12"
              style="border-radius: 1px"
              color="textSecondary"
            />
            <ui-text variant="body3" color="textSecondary">
              {{ txData.description }}
            </ui-text>
          </div>
          <ExplorerLink
            type="tx"
            disabled
            :hash="txData.txHash"
            tabindex="-1"
            class="tx-explorer-link --body"
          />
        </div>
        <TxTokenFlow :data="data" />
      </div>
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import type { TransactionItem } from './types'
import ExplorerLink from '@base/components/business/explorer-link'
import TxTokenFlow from './tx-token-flow'

const props = defineProps({
  data: {
    type: Object as PropType<TransactionItem>,
    default: () => ({}),
  },
})

const { data } = toRefs(props)
const { getOriginalSymbol } = useTokens()
const { size } = useScreenSize()

const { getDeOrderBookLink } = useDeOrderbookLinks('explorer')
const url = computed(() => {
  return getDeOrderBookLink('explorer', {
    hash: txData.value.txHash,
    type: 'tx',
  })
})

const getTxLabel = (tx: TransactionItem) => {
  switch (tx.label) {
    case 'DeOrder':
    case 'Collect':
    case 'Unwind':
      return 'DeOrder'
    case 'Stake':
    case 'Unstake':
    case 'Lock':
    case 'Withdraw':
      return 'Stake'
    case 'Claim':
    case 'BULLETClaim':
      return 'Claim'
    case 'Exercise':
    case 'BuyBULLET':
    case 'SellBULLET':
    case 'SoldBULLET':
      return 'Bullet'
    case 'Mint':
    case 'Redeem':
      return 'Others'
    default:
      return tx.label
  }
}

const txDescription = computed(() => {
  switch (data.value.action) {
    case 'DeOrderSell':
      return 'SELL'
    case 'DeOrderBuy':
      return 'BUY'
    case 'Lock':
      return `Lock ${getOriginalSymbol('DOB')}`
    case 'HODLStake':
      return `Stake HODL`
    case 'HODLUnstake':
      return `Unstake HODL`
    case 'Claim':
    case 'HODLClaim':
      return `Claim ${getOriginalSymbol('DOB')}`
    case 'DOBClaim':
      return `Claim rewards`
    case 'SellBULLET':
      return `List`
    case 'SoldBULLET':
      return `Sold`
    case 'BuyBULLET':
      return `Buy`
    case 'BULLETClaim':
      return `BULLET`
    default:
      return data.value.action
  }
})

const txData = computed(() => {
  return {
    ...data.value,
    date: new Date(Number(data.value.timestamp) * 1000),
    title: getTxLabel(data.value),
    description: txDescription.value,
  }
})

const explorerLinkVariant = computed(() => {
  if (size.value === 'sm') return 'body4'
  return 'textButton1'
})
</script>
<style lang="postcss" scoped>
.tx-list__group-header {
  width: 100%;
  padding: 8px 10px;
  background: var(--brand-color-secondary-hover);
}
.tx-list-item {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  &.--first {
    margin-top: 0.625rem;
  }
}
.tx-list-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tx-list-item__body {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1rem;
  background: transparent;
  border-radius: 0.75rem;
  & .tx-explorer-link {
    color: var(--brand-color-text-tertiary);
    & :deep(.ui-text) {
      color: var(--brand-color-text-secondary);
    }
  }
  &:hover {
    background: var(--brand-color-base-tertiary);
    & .tx-explorer-link {
      color: var(--brand-color-text-secondary);
      & :deep(.ui-text) {
        color: var(--brand-color-text-primary);
      }
    }
  }
  &:active {
    & .tx-explorer-link {
      color: var(--brand-color-text-primary);
      & :deep(.ui-text) {
        color: var(--brand-color-text-primary);
      }
    }
  }
  &:focus-visible {
    background: var(--brand-color-base-tertiary);
    outline: none;
    & .tx-explorer-link {
      color: var(--brand-color-text-primary);
      & :deep(.ui-text) {
        color: var(--brand-color-text-primary);
      }
    }
  }
}
.tx-list-item__time {
  padding: 3px 0;
  text-align: left;
  white-space: nowrap;
}
.tx-list-item__info {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
  padding-left: 0;
}
.tx-list-item__info__header {
  display: flex;
  gap: 0.75rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tx-list-item__info__label {
  display: flex;
  align-items: center;
}
.tx-explorer-link {
  pointer-events: none;
  &.--header {
    display: flex;
  }
  &.--body {
    display: none;
  }
}
.--divider {
  width: 1.5px;
  height: 14px;
  border-color: var(--brand-color-text-secondary);
}
.tx-list-item__data {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.tx-list-item__data-item {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  & span {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }
}
.tx-list-item__data-item-label {
  min-width: 1.5rem;
  padding: 3px 0;
}

@media screen and (min-width: 768px) {
  .tx-list-item {
    &.--first {
      margin-top: 0;
    }
  }
  .tx-list-item__body {
    flex-direction: row;
    justify-content: space-between;
  }
  .tx-list-item__time {
    width: 62px;
    text-align: right;
  }
  .tx-explorer-link {
    &.--header {
      display: none;
    }
    &.--body {
      display: flex;
    }
  }
  .tx-list-item__info {
    position: relative;
    gap: 1.25rem;
    padding-left: 21px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 100%;
      content: '';
      background: var(--brand-color-text-tertiary);
      border-radius: 1px;
    }
  }
  .tx-list-item__header {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
