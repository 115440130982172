<template>
  <NuxtLink to="/lock">
    <ui-alert
      v-if="visible"
      show-icon
      type="error"
      :title="message"
      @close="onClose"
    />
  </NuxtLink>
</template>
<script setup lang="ts">
import useWithdrawAlert from './useWithdrawAlert'
const { visible, message, onClose } = useWithdrawAlert()
</script>
