<template>
  <ui-flexbox
    v-if="isWithdrawAlertVisible || isBulletClaimAlertVisible"
    direction="column"
    full-width
    :gap="32"
    class="container--max"
    style="margin-top: 2rem"
  >
    <WithdrawAlert />
    <BulletClaimAlert />
  </ui-flexbox>
</template>
<script setup lang="ts">
import { BulletClaimAlert, useBulletClaimAlert } from './bullet-claim-alert'
import { WithdrawAlert, useWithdrawAlert } from './withdraw-alert'

const { visible: isWithdrawAlertVisible } = useWithdrawAlert()
const { visible: isBulletClaimAlertVisible } = useBulletClaimAlert()
</script>
