<template>
  <div class="account-tx">
    <TxListHeader :count="data.length" :loading="isLoading" />
    <TxList :data="data" :loading="isLoading" />
  </div>
</template>
<script setup lang="ts">
import TxListHeader from './TxListHeader.vue'
import TxList from './TxList.vue'
import dayjs from 'dayjs'
import type { TransactionItem } from './types'

const { trades, isLoading } = useTrades()
const data = computed<TransactionItem[]>(() => {
  return trades.value.map((tx, index: number) => {
    // The first transaction event of the day
    let isFirstTxOfDay = false
    if (index === 0) {
      isFirstTxOfDay = true
    } else {
      isFirstTxOfDay = !dayjs(
        Number(trades.value[index - 1].timestamp + '000'),
      ).isSame(Number(tx.timestamp + '000'), 'day')
    }

    return {
      ...tx,
      isFirstTxOfDay,
    }
  })
})
</script>
<style lang="postcss" scoped>
.account-tx {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  height: 100%;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .account-tx {
    gap: 1rem;
  }
}
</style>
