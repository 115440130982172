import { Big } from 'big.js'

export function useOnboardingState() {
  const { isConnected } = useWallet()
  const { divDecimals, getTokenBalance } = useTokens()
  const { totalSniperTokenAmount, loading } = useUserAssets()
  const route = useRoute()

  const open = ref(false)
  const doNotShowAgain = ref(false)
  const isOnboardingDialogPermanentlyHidden = useLocalStorage(
    'onboarding-dialog-perm-hidden',
    false,
    { mergeDefaults: true },
  )
  const handleDoNotShow = () => {
    isOnboardingDialogPermanentlyHidden.value = doNotShowAgain.value
  }

  const tokenBalanceSum = computed(() =>
    [
      divDecimals(getTokenBalance('DOB').value, 'DOB').value,
      divDecimals(getTokenBalance('uHODL').value, 'uHODL').value,
      divDecimals(getTokenBalance('sellTokenHODL').value, 'sellTokenHODL')
        .value,
    ]
      .reduce((acc, val) => acc.add(val), Big(0))
      .toNumber(),
  )

  watch(
    [
      isConnected,
      isOnboardingDialogPermanentlyHidden,
      tokenBalanceSum,
      totalSniperTokenAmount,
      loading,
    ],
    ([connected, isPermHidden, balance, snipers, isLoading]) => {
      open.value =
        (connected &&
          !isPermHidden &&
          Big(balance || 0).eq(0) &&
          Big(snipers.total || 0).eq(0) &&
          !isLoading) ||
        route.query.onboarding === 'true'
    },
    { immediate: true },
  )

  return {
    open,
    doNotShowAgain,
    handleDoNotShow,
  }
}
