<template>
  <ConnectWallet v-if="!isConnected" class="header-account-btn" />
  <div v-else>
    <Button class="header-account-btn" @click="toggleAccountModal(true)">
      {{ btnLabel }}
    </Button>
    <AccountModal
      :model-value="showAccountModal"
      @update:model-value="toggleAccountModal"
    />
  </div>
</template>
<script lang="ts" setup>
import Button from '@base/components/ui/button'
import ConnectWallet from '@base/components/business/ConnectWallet.vue'
import AccountModal from './account-modal'

const { address, isConnected } = toRefs(useWalletStore())
const { lookupAddress } = useENS()

const showAccountModal = ref(false)
const toggleAccountModal = (v: boolean) => {
  showAccountModal.value = v
}

const btnLabel = computed(() => {
  if (!isConnected.value || !address.value) return 'Connect Wallet'
  const ensName = lookupAddress(address.value).value.state || ''
  const label = ensName?.length > 0 ? ensName : address.value

  if (label.length <= 15) return label

  return label.slice(0, 5) + '........' + label.slice(-2)
})
</script>
<style lang="postcss" scoped>
.header-account-btn {
  width: 158px;
}
@media screen and (max-width: 1279px) {
  .header-account-btn {
    width: 135px;
  }
}
</style>
