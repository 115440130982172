<template>
  <client-only>
    <ui-drawer
      v-if="isMobileScreen"
      :model-value="modelValue"
      @update:model-value="updateModelValue"
    >
      <AccountBody :show-tx-list="showTxList" />
    </ui-drawer>
    <ui-dialog
      v-else
      width="717"
      :model-value="modelValue"
      :gap="24"
      @update:model-value="updateModelValue"
    >
      <template #header>
        <ui-text variant="h5" color="textPrimary" style="text-align: left">
          Account
        </ui-text>
      </template>
      <AccountBody :show-tx-list="showTxList" />
    </ui-dialog>
  </client-only>
</template>
<script lang="ts" setup>
import AccountBody from './AccountBody.vue'

defineProps({
  showTxList: {
    type: Boolean,
    default: true,
  },
})

const modelValue = defineModel<boolean>({ required: true })
const emits = defineEmits(['update:modelValue'])
function updateModelValue(value: boolean) {
  emits('update:modelValue', value)
}

const { size } = useScreenSize()
const isMobileScreen = computed(() => size.value === 'sm')
</script>
