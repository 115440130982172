<template>
  <nav v-if="!!links.length" class="ui-header__nav-links">
    <NuxtLink
      v-for="link in links"
      :key="link.name"
      :to="formatRoute(link.url)"
      tabindex="-1"
      class="ui-header__nav-link-item"
      :class="{ '--active': $route.path === link.url }"
    >
      <Button size="large" link>{{ link.name }}</Button>
    </NuxtLink>
  </nav>
</template>
<script lang="ts" setup>
import type { DeOrderBookApp } from '@base/types/app'
import Button from '@base/components/ui/button'
import { DOB_APPS_MAP } from '@base/utils/constants'

const props = defineProps({
  app: {
    type: String as PropType<DeOrderBookApp>,
    required: true,
  },
})

const { app } = toRefs(props)
const { t } = useCustomI18n()

const links = computed(() =>
  (DOB_APPS_MAP[app.value]?.links || []).map((link) => ({
    ...link,
    name: t(link.name, { DOB: getOriginalSymbol('DOB') }),
  })),
)

// TODO: remove this function when we have all route
const routes = useRouter()
const formatRoute = (route: string) => {
  const hasRoute = routes.getRoutes().find((_route) => _route.path === route)
  return hasRoute ? route : '/'
}
</script>
<style lang="postcss" scoped>
.ui-header__nav-links {
  display: flex;
  gap: 3rem;
  align-items: center;
}
.ui-header__nav-link-item {
  &:hover {
    &:deep(.ui-button.el-button) {
      color: var(--brand-color-primary-hover);
    }
  }
  &.--active {
    &:deep(.ui-button.el-button) {
      color: var(--brand-color-primary);
    }
  }
}
</style>
