<template>
  <el-progress v-bind="{ ...$attrs, ...props }" class="ui-loading-progress" />
</template>
<script setup lang="ts">
import { progressProps } from 'element-plus'

const props = defineProps({
  ...progressProps,
  indeterminate: {
    type: Boolean,
    default: true,
  },
  percentage: {
    type: Number,
    default: 50,
  },
  strokeWidth: {
    type: Number,
    default: 8,
  },
  showText: {
    type: Boolean,
    default: false,
  },
  duration: {
    type: Number,
    default: 2,
  },
})
</script>
<style lang="postcss" scoped>
.ui-loading-progress.el-progress {
  &:deep(.el-progress-bar__outer) {
    background-color: var(--brand-color-base-tertiary);
    border-radius: 8px;
  }
  &:deep(.el-progress-bar__inner) {
    background-color: var(--brand-color-secondary);
    border-radius: 8px;
  }
}
</style>
