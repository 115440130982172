<template>
  <ui-flexbox direction="column" :gap="4">
    <span
      v-for="(text, index) in step.description"
      :key="`${step.title}-${index}`"
    >
      {{ text }}
    </span>
    <span v-if="showPendingText">Pending...</span>
    <div v-if="active" style="padding: 10px 2px">
      <ui-loading-progress />
    </div>
  </ui-flexbox>
</template>
<script setup lang="ts">
const props = defineProps({
  step: {
    type: Object as PropType<{ title: string; description: string[] }>,
    required: true,
  },
  active: {
    type: Boolean,
    required: true,
  },
})

const { step, active } = toRefs(props)

const showPendingText = ref(false)

watch(
  () => active.value,
  (newVal) => {
    if (!newVal) {
      showPendingText.value = false
    } else {
      setTimeout(() => {
        if (!active.value) {
          showPendingText.value = false
          return
        }
        showPendingText.value = true
      }, 3000)
    }
  },
  { immediate: true },
)
</script>
