<template>
  <div class="tx-list-header">
    <ui-text variant="body2" color="textSecondary">
      Recent activity{{ loading ? '' : ` ( ${count} )` }}
    </ui-text>
    <ExplorerLink type="address" :hash="address" />
  </div>
</template>
<script setup lang="ts">
import ExplorerLink from '@base/components/business/explorer-link'

defineProps({
  count: {
    type: Number,
    required: true,
    default: 0,
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const { address } = toRefs(useWalletStore())
</script>
<style lang="postcss" scoped>
.tx-list-header {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  padding: 0.25rem;
}
.tx-list-header__link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
