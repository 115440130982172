<template>
  <ui-flexbox
    direction="column"
    align="center"
    :gap="40"
    full-width
    style="padding-top: 247px"
  >
    <img
      src="@base/assets/img/tx-dialog-error.webp"
      alt="Transaction failed"
      class="tx-failure-img"
    />
    <ui-text
      color="error"
      variant="body4"
      centered
      style="z-index: 2001; padding: 20px 0"
    >
      An error occurred.
      <br />
      Please go back and try again.
    </ui-text>
  </ui-flexbox>
</template>
<script setup lang="ts"></script>
<style scoped lang="postcss">
.tx-failure-img {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1999;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}
</style>
