<template>
  <client-only>
    <Menu
      mode="vertical"
      width="100%"
      class="header-menu-list"
      :class="{
        '--divided': divided,
      }"
    >
      <template v-if="!isDesktopScreen">
        <MenuItem v-for="link in navLinks" :key="link.name">
          <NuxtLink :to="link.url" class="header-menu-item-link">
            {{ link.name }}
            <Icon v-if="$route.path === link.url" :svg="CheckmarkIcon" />
          </NuxtLink>
        </MenuItem>
      </template>
      <MenuItem
        v-for="(link, index) in externalLinks"
        :key="link.name"
        :end-icon="HyperlinkIcon"
        :divided="!isDesktopScreen && navLinks.length > 0 && index === 0"
      >
        <NuxtLink :to="link.url" external target="_blank" style="width: 100%">
          {{ link.name }}
        </NuxtLink>
      </MenuItem>
    </Menu>
  </client-only>
</template>
<script setup lang="ts">
import Menu, { MenuItem } from '@base/components/ui/menu'
import Icon from '@base/components/ui/icon'
import { DOB_APPS_MAP } from '@base/utils/constants'
import type { DeOrderBookApp } from '@base/types/app'
import HyperlinkIcon from '@base/assets/img/svg/icon-hyperlink.svg'
import CheckmarkIcon from '@base/assets/img/svg/icon-check.svg'

const props = defineProps({
  app: {
    type: String as PropType<DeOrderBookApp>,
    required: true,
  },
  divided: {
    type: Boolean,
    default: false,
  },
})

const { app } = toRefs(props)
const { isDesktopScreen } = useScreenSize()
const { t } = useCustomI18n()

const navLinks = computed(() =>
  (DOB_APPS_MAP[app.value]?.links || []).map((link) => ({
    ...link,
    name: t(link.name, { DOB: getOriginalSymbol('DOB') }),
  })),
)

const externalLinks = computed(
  () => DOB_APPS_MAP[app.value]?.externalLinks || [],
)
</script>
<style lang="postcss" scoped>
.header-menu-list {
  &.--divided {
    padding-bottom: 12px;
    border-bottom: 1px solid var(--brand-color-text-tertiary);
  }
}
.header-menu-item-link {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 24px;
}
</style>
