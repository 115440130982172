<template>
  <ui-popover
    :show-arrow="false"
    placement="bottom-end"
    automatic-dropdown
    :width="215"
    @before-enter="fetchLastTimestamp"
  >
    <template #reference>
      <ui-icon
        class="faucet-icon"
        tabindex="0"
        :svg="FaucetIcon"
        size="24"
        color="inherit"
        :class="{ '--active': isFaucetClaimable }"
      />
    </template>
    <ui-flexbox full-width direction="column" :gap="12">
      <ui-flexbox align="center" :gap="4">
        <ui-icon :svg="FaucetIcon" size="20" />
        <ui-text variant="body4" color="textSecondary">Testnet Faucet</ui-text>
      </ui-flexbox>
      <ui-text variant="body5" color="textTertiary">
        You can receive a test token 24 hours after your previous request.
      </ui-text>
      <ui-button full-width :disabled @click="onSubmit">
        {{ btnLabel }}
      </ui-button>
    </ui-flexbox>
  </ui-popover>
</template>
<script lang="ts" setup>
import FaucetIcon from '@base/assets/img/svg/icon-faucet.svg'
const ONE_DAY_MILLIS = 24 * 60 * 60 * 1000

const { userLastWithdrawTimestamp, userLastWithdrawTimestampStatus } =
  toRefs(useFaucetStore())

const { fetchLastTimestamp } = useFaucetStore()
const { onSubmit, loading } = useClaimFaucetTokens()

const disabled = computed(() => {
  return (
    loading.value ||
    userLastWithdrawTimestampStatus.value !== 'success' ||
    userLastWithdrawTimestamp.value > Date.now() - ONE_DAY_MILLIS
  )
})

const countdownValue = ref(0)

onMounted(() => {
  const interval = setInterval(() => {
    const endDate = userLastWithdrawTimestamp.value + ONE_DAY_MILLIS
    const newValue = (endDate - Date.now()) / 1000
    countdownValue.value = newValue <= 0 ? 0 : newValue
  }, 1000)
  return () => clearInterval(interval)
})

const formatSeconds = (secs: number) => {
  if (secs <= 0) return '00:00:00'
  const pad = (n: number) =>
    n < 10 ? (n < 0 ? '-' : '') + `0${Math.abs(n)}` : n

  const h = Math.floor(secs / 3600)
  const m = Math.floor(secs / 60) - h * 60
  const s = Math.floor(secs - h * 3600 - m * 60)

  return `${pad(h)}:${pad(m)}:${pad(s)}`
}

const nextFaucetCountdown = computed(() => {
  return formatSeconds(countdownValue.value)
})

const isFaucetClaimable = computed(() => {
  return (
    userLastWithdrawTimestampStatus.value === 'success' &&
    (userLastWithdrawTimestamp.value === 0 ||
      (userLastWithdrawTimestamp.value !== 0 &&
        userLastWithdrawTimestamp.value + ONE_DAY_MILLIS < Date.now()))
  )
})

const btnLabel = computed(() => {
  if (
    userLastWithdrawTimestamp.value !== 0 &&
    userLastWithdrawTimestamp.value + ONE_DAY_MILLIS > Date.now()
  ) {
    return `${nextFaucetCountdown.value} left to receive`
  }
  return 'Receive'
})
</script>
<style lang="postcss" scoped>
.faucet-icon.ui-icon {
  color: var(--brand-color-text-tertiary);
  border-radius: 4px;
  &:focus-visible {
    color: var(--brand-color-text-primary);
  }
  &.--active {
    color: var(--brand-color-primary);
    &:focus-visible {
      color: var(--brand-color-primary-focus);
    }
  }
}
</style>
