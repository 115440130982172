<template>
  <ui-drawer
    :model-value="modelValue"
    @update:model-value="updateModelValue"
    @close="onBeforeClose"
  >
    <template #header>
      <ui-flexbox direction="column" align="center">
        <ui-text variant="h5">Welcome to DeOrderBook</ui-text>
        <ui-text variant="body4" color="textTertiary">
          Maximizing your asset with DeOrderBook
        </ui-text>
      </ui-flexbox>
    </template>
    <ui-flexbox
      direction="column"
      align="center"
      :gap="20"
      style="margin-top: 20px"
    >
      <ui-steps
        direction="horizontal"
        :active="activeStep"
        style="width: 100%; max-width: 220px"
      >
        <ui-steps-step v-for="step of steps" :key="step" />
      </ui-steps>
      <ui-text variant="body2" color="primary">
        {{ steps.at(activeStep) }}
      </ui-text>
      <OnboardingCarousel
        :active-step-index="activeStep"
        @update:active-step="updateActiveStep"
      />
      <ui-checkbox
        :model-value="notShow"
        @update:model-value="updateCheckboxValue"
      >
        Do not show again
      </ui-checkbox>
    </ui-flexbox>
  </ui-drawer>
</template>
<script setup lang="ts">
import OnboardingCarousel from './OnboardingCarousel.vue'

defineProps({
  notShow: {
    type: Boolean,
    required: true,
  },
  steps: {
    type: Array as PropType<string[]>,
    required: true,
  },
})
const activeStep = ref(0)
const updateActiveStep = (value: number) => {
  activeStep.value = value
}

const modelValue = defineModel<boolean>({ required: true })
const emits = defineEmits([
  'update:modelValue',
  'update:notShow',
  'beforeClose',
])
function updateModelValue(value: boolean) {
  emits('update:modelValue', value)
}
function updateCheckboxValue(value: boolean) {
  emits('update:notShow', value)
}
function onBeforeClose() {
  emits('beforeClose')
}
</script>
<style scoped lang="postcss">
.onboarding-dialog__header {
  width: 100%;
  max-width: 316px;
}
</style>
