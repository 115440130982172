<template>
  <client-only>
    <OnboardingDrawer
      v-if="isMobileScreen"
      v-model="open"
      v-model:not-show="doNotShowAgain"
      :steps
      @before-close="handleDoNotShow"
    />
    <OnboardingDialog
      v-else
      v-model="open"
      v-model:not-show="doNotShowAgain"
      :steps
      @before-close="handleDoNotShow"
    />
  </client-only>
</template>
<script setup lang="ts">
import OnboardingDrawer from './OnboardingDrawer.vue'
import OnboardingDialog from './OnboardingDialog.vue'
import { useOnboardingState } from './useOnboardingState'
const { isMobileScreen } = useScreenSize()
const { dobSymbol } = useLockInfo()

const { open, doNotShowAgain, handleDoNotShow } = useOnboardingState()

const steps = computed(() => [
  'Staking or DeOrder',
  `Earning ${dobSymbol.value}, Locking ${dobSymbol.value}`,
  'Play with BULLET',
])
</script>
