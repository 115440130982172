<template>
  <el-step v-bind="{ ...$attrs, ...props }" class="ui-step">
    <template #icon>
      <slot name="icon"></slot>
    </template>
    <template #title>
      <slot name="title"></slot>
    </template>
    <template #description>
      <slot name="description"></slot>
    </template>
  </el-step>
</template>
<script setup lang="ts">
import { stepProps } from 'element-plus'

const props = defineProps({
  ...stepProps,
})
</script>
<style lang="postcss" scoped>
/* stylelint-disable plugin/no-unsupported-browser-features */
.ui-step.el-step {
  --ui-step-wait-color: var(--brand-color-text-tertiary);
  --ui-step-process-color: var(--brand-color-primary);
  --ui-step-finish-color: var(--brand-color-primary);
  --ui-step-error-color: var(--brand-color-error);
  --ui-step-success-color: var(--brand-color-success);

  --ui-step-icon-size: 1.25rem;
  --ui-step-line-size: 1px;

  font-family: var(--font-family-primary);

  &:deep(.el-step__icon-inner) {
    display: none;
  }
  &:deep(.el-step__line-inner) {
    border-width: 0px !important;
  }
  &.is-horizontal {
    &:deep(.el-step__head) {
      height: var(--ui-step-icon-size);
    }
    &:deep(.el-step__main) {
      padding-top: 0.5rem;
    }
    &:deep(.el-step__line) {
      top: calc(
        (var(--ui-step-icon-size) / 2) - (var(--ui-step-line-size) / 2)
      );
      left: var(--ui-step-icon-size);
      height: var(--ui-step-line-size);
      transition: all 300ms ease-in-out;
    }
  }
  &.is-vertical {
    &:deep(.el-step__head) {
      width: var(--ui-step-icon-size);
    }
    &:deep(.el-step__main) {
      padding-bottom: 2.375rem;
      padding-left: 0.5rem;
    }
    &:deep(.el-step__line) {
      top: var(--ui-step-icon-size);
      left: calc(
        (var(--ui-step-icon-size) / 2) - (var(--ui-step-line-size) / 2)
      );
      width: var(--ui-step-line-size);
      transition: all 300ms ease-in-out;
    }
  }
  &:deep(.el-step__icon) {
    width: var(--ui-step-icon-size);
    height: var(--ui-step-icon-size);
    border-style: solid;
    border-width: 5px;
    border-radius: 50%;
    transition: all 300ms ease-in-out;
  }
  &:deep(.el-step__title) {
    padding-bottom: 0.25rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.375rem;
    transition: all 300ms ease-in-out;
  }
  &:deep(.el-step__description) {
    padding: 0;
    margin-top: 0;
    font-size: 0.75rem;
    font-weight: 300;
    line-height: 1rem;
    transition: all 300ms ease-in-out;
  }
  &:deep(.el-step__head.is-finish) {
    color: var(--ui-step-finish-color);
    border-color: var(--ui-step-finish-color);
    & .el-step__line {
      background-color: var(--ui-step-finish-color);
    }
    & .el-step__icon {
      background-color: var(--ui-step-finish-color);
    }
  }
  &:deep(.el-step__head.is-process) {
    color: var(--ui-step-process-color);
    border-color: var(--ui-step-process-color);
    & .el-step__line {
      background-color: var(--ui-step-process-color);
    }
    & .el-step__icon {
      background-color: var(--ui-step-process-color);
    }
  }
  &:deep(.el-step__head.is-wait) {
    color: var(--ui-step-wait-color);
    border-color: var(--ui-step-wait-color);
    & .el-step__line {
      background-color: var(--ui-step-wait-color);
    }
    & .el-step__icon {
      background-color: transparent;
    }
  }
  &:deep(.el-step__head.is-error) {
    color: var(--ui-step-error-color);
    border-color: var(--ui-step-error-color);
    & .el-step__line {
      background-color: var(--ui-step-error-color);
    }
    & .el-step__icon {
      background-color: transparent;
    }
  }
  &:deep(.el-step__head.is-success) {
    color: var(--ui-step-success-color);
    border-color: var(--ui-step-success-color);
    & .el-step__line {
      background-color: var(--ui-step-success-color);
    }
    & .el-step__icon {
      background-color: transparent;
    }
  }
  &:deep(.el-step__title.is-finish),
  &:deep(.el-step__description.is-finish) {
    color: var(--ui-step-finish-color);
  }
  &:deep(.el-step__title.is-process),
  &:deep(.el-step__description.is-process) {
    color: var(--ui-step-process-color);
  }
  &:deep(.el-step__title.is-wait),
  &:deep(.el-step__description.is-wait) {
    color: var(--ui-step-wait-color);
  }
  &:deep(.el-step__title.is-error),
  &:deep(.el-step__description.is-error) {
    color: var(--ui-step-error-color);
  }
  &:deep(.el-step__title.is-success),
  &:deep(.el-step__description.is-success) {
    color: var(--ui-step-success-color);
  }
  &:has(.is-finish) {
    opacity: 0.2;
  }
}
</style>
