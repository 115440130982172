import type { Overrides } from 'ethers'
import { parseUnits } from 'ethers'

export enum GasType {
  LOW = 'LOW',
  AVG = 'AVG',
  HIGH = 'HIGH',
}
interface GasInfo {
  type: GasType
  label: string
  value: string
  unit: string
}

const GAS_UNIT = 'Gwei'

export type PromiseOrValue<T> = T | Promise<T>

export function useGasInfo() {
  const { fees, loading } = toRefs(useGasFeeStore())
  const { isTestnet } = useNetwork()

  const selectedGasType: Ref<GasType | null> = ref(null)
  const data = ref<GasInfo[]>([] as GasInfo[])

  const gasFeeOverrides: Ref<
    Overrides & { from?: PromiseOrValue<string> | undefined }
  > = computed(() => {
    if (isTestnet.value) return {}

    const gasInfo = data.value.find(
      (item) => item.type === selectedGasType.value,
    )

    if (!gasInfo) return {}

    return {
      gasLimit: 100000,
      maxPriorityFeePerGas: parseUnits(gasInfo.value, GAS_UNIT).toString(),
      maxFeePerGas: parseUnits(gasInfo.value, GAS_UNIT),
    }
  })

  watch(
    () => fees.value,
    (newFees) => {
      if (newFees) {
        data.value = [
          {
            type: GasType.HIGH,
            label: 'High',
            value: newFees.fastGasPrice,
            unit: GAS_UNIT,
          },
          {
            type: GasType.AVG,
            label: 'Average',
            value: newFees.averageGasPrice,
            unit: GAS_UNIT,
          },
          {
            type: GasType.LOW,
            label: 'Low',
            value: newFees.safeLowGasPrice,
            unit: GAS_UNIT,
          },
        ] as GasInfo[]
        if (selectedGasType.value === null) {
          selectedGasType.value = GasType.AVG
        }
      }
    },
    { immediate: true },
  )

  return { data, loading, selectedGasType, gasFeeOverrides }
}
