export const useDevPasswordAuth = () => {
  const isDevEnabled = useSessionStorage('isDevEnabled', false)
  const config = useRuntimeConfig()

  const devHost = config?.public?.DEV_ENV_URL
  const passwordKey = config?.public?.DEV_ENV_PASSWORD_KEY

  onMounted(() => {
    const host = window?.location?.host

    if (!passwordKey || isDevEnabled.value || host !== devHost) return

    let passwordInput

    do {
      passwordInput = prompt('Please enter password:')
    } while (passwordInput !== passwordKey)

    if (passwordInput === passwordKey) {
      isDevEnabled.value = true
    }
  })
}
