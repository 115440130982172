export default function useWithdrawAlert() {
  const { isWithdrawEnabled, dobSymbol } = useLockInfo()

  const isHidden = useSessionStorage('withdraw-alert-hidden', false)

  const onClose = (e: MouseEvent) => {
    e.stopPropagation()
    isHidden.value = true
  }

  const visible = computed(() => isWithdrawEnabled.value && !isHidden.value)

  const message = computed(
    () => `You can now withdraw your ${dobSymbol.value}.`,
  )

  return {
    visible,
    message,
    onClose,
  }
}
