<template>
  <client-only>
    <Header
      app="main"
      :class="{
        shifted: isFaucetAlertVisible,
      }"
    ></Header>
  </client-only>
</template>
<script lang="ts" setup>
import Header from '@base/components/layout/header'
const { isFaucetAlertVisible } = useFaucetState()
</script>
<style lang="postcss" scoped>
.shifted {
  top: var(--faucet-alert-height-desktop);
}
@media screen and (max-width: 1279px) {
  .shifted {
    top: var(--faucet-alert-height-tablet);
  }
}
@media screen and (max-width: 767px) {
  .shifted {
    top: var(--faucet-alert-height-mobile);
  }
}
</style>
