import { defineStore } from 'pinia'
import { getTransactionList } from '@deorderbook/sdk'

export const useTradesStore = defineStore('trades', () => {
  const { address } = toRefs(useWalletStore())
  const {
    execute,
    data: dataOrigin,
    ...rest
  } = useCacheableAsyncData((...args) => {
    const options = args.at(0)
    const params = {
      first: options?.first || options?.pageSize || 10, // TODO: remove pageSize support
      where: {
        account:
          options?.address ||
          options?.where?.account ||
          address.value ||
          undefined,
        ...options?.where,
      },
    }
    return getTransactionList(params)
  })

  const data = computed(() => dataOrigin.value || [])

  return {
    data,
    execute,
    ...rest,
  }
})
