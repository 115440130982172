<template>
  <Icon
    ref="buttonRef"
    v-click-outside="onClickOutside"
    :svg="MenuIcon"
    type="button"
    @click="toggleDrawer"
  />
  <Popover
    v-if="!isMobileScreen"
    ref="popoverRef"
    trigger="click"
    virtual-triggering
    :virtual-ref="buttonRef"
    :show-arrow="false"
    width="max-content"
    placement="bottom-end"
    :offset="31"
    :popper-style="{
      border: '1px solid var(--brand-color-base-tertiary)',
      background: 'var(--brand-color-base-secondary)',
      borderRadius: '24px',
    }"
  >
    <HeaderMenuList :app="app" style="width: 220px" />
  </Popover>
  <Drawer v-if="isMobileScreen" v-model="isDrawerOpen">
    <div class="header-menu-drawer">
      <HeaderMenuList :app="app" divided />
      <Footer />
    </div>
  </Drawer>
</template>
<script setup lang="ts">
import Drawer from '@base/components/ui/drawer'
import Popover from '@base/components/ui/popover'
import HeaderMenuList from './HeaderMenuList.vue'
import type { DeOrderBookApp } from '@base/types/app'
import { ClickOutside as vClickOutside } from 'element-plus'
import Footer from '../../../footer'
import Icon from '@base/components/ui/icon'
import MenuIcon from '@base/assets/img/svg/icon-menu.svg'

defineProps({
  app: {
    type: String as PropType<DeOrderBookApp>,
    required: true,
  },
})

const { isMobileScreen } = useScreenSize()

/* POPOVER MENU */
const buttonRef = ref()
const popoverRef = ref()
const onClickOutside = () => {
  unref(popoverRef)?.popperRef?.delayHide?.()
}

/* DRAWER MENU */
const isDrawerOpen = ref(false)
const toggleDrawer = () => {
  if (!isMobileScreen.value) return
  isDrawerOpen.value = !isDrawerOpen.value
}
</script>
<style lang="postcss" scoped>
.header-menu-drawer {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  justify-content: space-between;
  height: 100%;
}
</style>
