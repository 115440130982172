<template>
  <el-skeleton
    loading
    animated
    :count="5"
    class="tx-list-skeleton"
    effect="dark"
  >
    <template #template>
      <el-skeleton-item variant="rect" />
    </template>
  </el-skeleton>
</template>
<script setup lang="ts"></script>
<style lang="postcss" scoped>
.tx-list-skeleton {
  display: flex;
  flex-direction: column;
  gap: 1.125rem;
  margin-top: 1rem;
  --el-skeleton-color: var(--brand-color-secondary-hover);
  --el-skeleton-to-color: var(--brand-color-secondary);

  & .el-skeleton__item {
    width: 100%;
    height: 194px;
    border-radius: 0.75rem;
  }
}

@media screen and (min-width: 768px) {
  .tx-list-skeleton {
    gap: 0.75rem;
    & .el-skeleton__item {
      height: 158px;
    }
  }
}
</style>
