import DiscordIcon from '@base/assets/img/svg/icon-discord.svg'
import GitHubIcon from '@base/assets/img/svg/icon-github.svg'
import TwitterIcon from '@base/assets/img/svg/icon-twitter.svg'
import { DOB_EXTERNAL_LINKS_MAP } from '@base/utils/constants'

export const LICENSE_URL = DOB_EXTERNAL_LINKS_MAP.LICENSE.url

export const DOCUMENT_LINKS: { title: string; url: string }[] = [
  {
    title: 'Terms',
    url: DOB_EXTERNAL_LINKS_MAP.TERMS.url,
  },
  {
    title: 'Privacy',
    url: DOB_EXTERNAL_LINKS_MAP.PRIVACY.url,
  },
  {
    title: 'Disclaimer',
    url: DOB_EXTERNAL_LINKS_MAP.DISCLAIMER.url,
  },
] as const

export const SOCIAL_LINKS: {
  title: string
  url: string
  icon: Component
}[] = [
  {
    title: 'Discord',
    url: DOB_EXTERNAL_LINKS_MAP.DISCORD.url,
    icon: DiscordIcon,
  },
  {
    title: 'GitHub',
    url: DOB_EXTERNAL_LINKS_MAP.GITHUB.url,
    icon: GitHubIcon,
  },
  {
    title: 'Twitter',
    url: DOB_EXTERNAL_LINKS_MAP.TWITTER.url,
    icon: TwitterIcon,
  },
] as const
