<template>
  <client-only>
    <ui-dialog
      :model-value="open"
      :width="364"
      :gap="28"
      lock-scroll
      modal
      :close-on-click-modal="isCloseEnabled"
      :close-on-press-escape="isCloseEnabled"
      :show-close="isCloseEnabled"
      :z-index="2000"
      class="tx-dialog"
      @close="closeTxDialog"
    >
      <template #header>
        <TxDialogHeader />
      </template>
      <template v-if="state === TxDialogState.PENDING">
        <TxPendingInfo />
      </template>
      <template v-else-if="state === TxDialogState.SUCCESS">
        <TxSuccessInfo />
      </template>
      <template v-else-if="state === TxDialogState.FAILURE">
        <TxFailureInfo />
      </template>
    </ui-dialog>
  </client-only>
</template>
<script setup lang="ts">
import { TxDialogState } from '@base/types/tx-dialog'
import TxDialogHeader from './TxDialogHeader.vue'
import TxPendingInfo from './tx-pending-info'
import TxFailureInfo from './TxFailureInfo.vue'
import TxSuccessInfo from './tx-success-info'

const { open, closeTxDialog, state, isCloseEnabled } =
  toRefs(useTxDialogStore())
</script>
<style lang="postcss">
.tx-dialog.el-dialog .el-dialog__header {
  z-index: 2001;
}
</style>
