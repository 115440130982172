<template>
  <NuxtLink
    :to="url"
    external
    target="_blank"
    class="explorer-link"
    data-testId="explorer-link"
    :disabled
  >
    <ui-text :variant="textVariant" color="textSecondary">
      {{ text }}
    </ui-text>
    <ui-icon v-bind="uiIconProps" />
  </NuxtLink>
</template>
<script setup lang="ts">
import HyperlinkIcon from '@base/assets/img/svg/icon-hyperlink.svg'
import type { TextVariant } from '@base/components/ui/text'
import type { IconPublicProps } from '@base/components/ui/icon'
const props = defineProps({
  /**
   * @description Type of the link (transaction or wallet address)
   */
  type: {
    type: String as PropType<'tx' | 'address'>,
    required: true,
  },
  /**
   * @description Transaction hash or wallet address
   */
  hash: {
    type: String,
    required: true,
  },
  /**
   * @description Text to display in the link
   * @default 'See in Explorer'
   */
  text: {
    type: String,
    default: 'See in Explorer',
  },
  /**
   * @description Variant of the text component
   * @default textButton1
   */
  textVariant: {
    type: String as PropType<TextVariant>,
    default: 'textButton1',
  },
  /**
   * @description Props for the icon component
   * @default {svg: HyperlinkIcon}
   */
  iconProps: {
    type: Object as PropType<IconPublicProps>,
    default: () => ({}),
  },
  /**
   * @description Whether the link is disabled
   * @default false
   */
  disabled: {
    type: Boolean,
    default: false,
  },
})
const { type, hash, text } = toRefs(props)
const { getDeOrderBookLink } = useDeOrderbookLinks('explorer')
const url = computed(() => {
  return getDeOrderBookLink('explorer', { hash: hash.value, type: type.value })
})

const uiIconProps = computed(() => {
  return {
    ...props.iconProps,
    svg: props.iconProps.svg || HyperlinkIcon,
    color: props.iconProps.color || 'textTertiary',
    size: props.iconProps.size || '16',
  } as IconPublicProps
})
</script>
<style lang="postcss" scoped>
.explorer-link {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: max-content;
  color: var(--brand-color-text-tertiary);
  border-radius: 4px;
  &:hover {
    color: var(--brand-color-text-secondary);
    & .ui-text {
      color: var(--brand-color-text-primary);
    }
  }
  &:active {
    color: var(--brand-color-text-primary);
    & .ui-text {
      color: var(--brand-color-text-primary);
    }
  }
  &[disabled='true'] {
    color: var(--brand-color-text-tertiary);
    & .ui-text {
      color: var(--brand-color-text-tertiary);
    }
  }
  &:focus-visible {
    outline: 1px solid var(--brand-color-text-tertiary);
  }
}
</style>
