<template>
  <div class="network-select-container">
    <client-only>
      <Select
        :model-value="selectedNetwork"
        automatic-dropdown
        value-key="id"
        placeholder="Select network"
        :class="[
          'network-select',
          {
            '--unsupported': !selectedNetwork?.isActive,
          },
        ]"
        @change="handleNetworkSelect"
      >
        <template #prefix>
          <div
            v-if="!!selectedNetwork && !!selectedNetwork.icon"
            class="network-icon-wrapper --margin"
          >
            <img :src="selectedNetwork.icon" alt="" />
          </div>
          <div v-else class="network-select_unsupported">
            <Icon :svg="IconInfo" color="var(--brand-color-error)" />
            <Text variant="body2" color="error">Unsupported</Text>
          </div>
        </template>
        <el-option-group
          v-for="group in CHAIN_GROUPS"
          :key="group.env"
          :label="group.label"
          class="network-select-group"
        >
          <Option
            v-for="chain in group.chains"
            :key="chain.id"
            :value="chain"
            :label="chain.name"
            use-custom-slot
          >
            <div class="network-select-option">
              <div v-if="!!chain.icon" class="network-icon-wrapper">
                <img :src="chain.icon" alt="" />
              </div>
              {{ chain.name }}
            </div>
            <CheckmarkIcon
              v-if="chain.id === selectedNetwork?.id"
              filled
              style="width: 24px; height: 24px"
            />
          </Option>
        </el-option-group>
      </Select>
    </client-only>
  </div>
</template>
<script lang="ts" setup>
import Select, { Option } from '@base/components/ui/select'
import Icon from '@base/components/ui/icon'
import Text from '@base/components/ui/text'
import IconInfo from '@base/assets/img/svg/icon-info.svg'
import { type Chain, SUPPORTED_CHAINS } from '@deorderbook/contracts'
import { ChainId, ChainEnvironment } from '@deorderbook/contracts'
import EthLogo from '@base/assets/img/ethereum-logo.svg?url'
import ScrollLogo from '@base/assets/img/scroll-logo.svg?url'
import CheckmarkIcon from '@base/assets/img/svg/checkmark-circle.svg'

const { currentChain } = useChains()

interface IChain extends Chain {
  icon: string | null
}

const CHAINS = SUPPORTED_CHAINS.map((chain) => ({
  ...chain,
  icon: getNetworkIcon(chain.id),
}))

const CHAIN_GROUPS: {
  label: string
  env: ChainEnvironment
  chains: IChain[]
}[] = CHAINS.reduce(
  (acc, chain) => {
    const groupIndex = acc.findIndex((group) => group.env === chain.environment)
    acc[groupIndex].chains.push(chain)
    return acc
  },
  [
    { label: 'Mainnet', env: ChainEnvironment.PROD, chains: [] as IChain[] },
    { label: 'Testnet', env: ChainEnvironment.TEST, chains: [] as IChain[] },
  ] as { label: string; env: ChainEnvironment; chains: IChain[] }[],
)

const selectedNetwork = ref({} as IChain)

onMounted(() => {
  window?.ethereum?.on('chainChanged', () => {
    window?.location?.reload()
  })
  window?.ethereum?.on('accountsChanged', () => {
    window?.location?.reload()
  })
})

function getNetworkIcon(id: ChainId) {
  switch (id) {
    case ChainId.ETH_MAINNET:
      return EthLogo
    case ChainId.ETH_SEPOLIA:
      return EthLogo
    case ChainId.SCROLL_SEPOLIA:
      return ScrollLogo
    default:
      return null
  }
}
const handleNetworkSelect = (chain: IChain) => {
  useSwitchChain(chain.id)
}

watch(
  currentChain,
  () => {
    const newChain = CHAINS.find((n) => n.id === currentChain.value?.chainId)
    if (!newChain) return
    selectedNetwork.value = newChain
  },
  {
    immediate: true,
    deep: true,
  },
)
</script>
<style lang="postcss" scoped>
.network-select-container {
  width: 175px; /* fixed value so that even longest network name fits (Scroll Sepolia) */
}
.network-select {
  &.--unsupported {
    &:deep(.el-select__caret) {
      color: var(--brand-color-error);
    }
  }
}
.network-select-option {
  display: flex;
  gap: 4px;
  align-items: center;
}
.network-select_unsupported {
  display: flex;
  gap: 4px;
  align-items: center;
  &:deep(circle) {
    stroke: var(--brand-color-error);
  }
}
.network-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  & img {
    width: 18px;
    height: 18px;
  }
  &.--margin {
    margin-right: 8px;
  }
}
/* stylelint-disable-next-line plugin/no-unsupported-browser-features */
.network-select.ui-select.el-select:deep(
    .el-select-dropdown__list:has(.el-select-group__wrap)
  ) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  row-gap: 0.5rem;
  align-items: flex-start;
  padding: 0.75rem;
}
.network-select-group.el-select-group__wrap {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  &:not(:last-of-type) {
    padding-bottom: 0.5rem;
    &::after {
      bottom: 0;
      left: 0;
      width: 100%;
      background: var(--brand-color-text-tertiary);
    }
  }
  &:deep(.el-select-group__title) {
    padding: 4px;
    padding-left: 4px;
    font-family: var(--font-family-primary);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: var(--brand-color-text-tertiary);
  }
}
@media screen and (max-width: 767px) {
  .network-select-container {
    width: 52px;
  }
  .network-select {
    &:deep(.el-select__placeholder) {
      display: none;
    }
  }
  .network-select_unsupported {
    & .ui-text {
      display: none;
    }
  }
  .network-icon-wrapper {
    &.--margin {
      margin-right: 4px;
    }
  }
}
</style>
