<template>
  <client-only>
    <ui-dialog
      v-model="open"
      :width="364"
      title="Testnet faucet open"
      subtitle="DeOrderBook now offers a testnet faucet! Claim your access and enjoy all features."
      :before-close="onBeforeClose"
      @open="onBeforeOpen"
    >
      <ui-flexbox direction="column" align="center" :gap="20">
        <div class="faucet-img-container">
          <img :src="FaucetImg" alt="Faucet" class="faucet-img" />
        </div>
        <ui-flexbox
          v-loading="tokensLoading"
          direction="column"
          :gap="16"
          class="faucet-info"
          full-width
        >
          <ui-gridbox
            :column="`repeat(${tokens.length > 3 ? 3 : tokens.length}, 1fr)`"
            full-width
            style="min-height: 44px"
          >
            <ui-flexbox
              v-for="token of tokens"
              :key="token.token"
              direction="column"
              align="center"
              :gap="4"
            >
              <ui-text variant="body3" color="textTertiary">
                {{ token.token }}
              </ui-text>
              <ui-text variant="body2" color="textSecondary">
                {{ token.amount }}
              </ui-text>
            </ui-flexbox>
          </ui-gridbox>
          <ui-text variant="body5" color="textTertiary" centered>
            Testnet tokens are specifically for testnet use.
            <br />
            For the Sepolia network, use “WBTC” tokens, and for the Scroll
            Sepolia network, use “WETH” tokens.
          </ui-text>
        </ui-flexbox>
        <div style="width: 100%">
          <ui-checkbox v-model="doNotShowAgain">Do not show again</ui-checkbox>
        </div>
      </ui-flexbox>
    </ui-dialog>
  </client-only>
</template>
<script setup lang="ts">
import FaucetImg from '@base/assets/img/faucet.png'
const open = ref(false)
const doNotShowAgain = ref(false)
const { isConnected } = useWallet()
const { fetchTokenTransferAmounts } = useFaucetStore()
const { tokens, tokensLoading } = toRefs(useFaucetStore())
const { isTestnet } = useNetwork()

const { isFaucetAlertPermanentlyHidden, permanentlyHideFaucetAlert } =
  useFaucetState()

const onBeforeClose = (done: () => void) => {
  if (doNotShowAgain.value) {
    permanentlyHideFaucetAlert()
  }
  done()
}

const onBeforeOpen = () => {
  if (tokens.value.length > 0) return
  fetchTokenTransferAmounts()
}

watch(
  [isConnected, isFaucetAlertPermanentlyHidden, isTestnet],
  ([connected, hidden, testnet]) => {
    open.value = connected && testnet && !hidden
  },
  { immediate: true },
)
</script>
<style scoped lang="postcss">
.faucet-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}
.faucet-img {
  width: auto;
  height: 80px;
}
.faucet-info {
  padding: 0.875rem;
  background: var(--brand-color-base-tertiary);
  border-radius: 0.875rem;
  &:deep(.el-loading-mask) {
    border-radius: 0.875rem;
  }
}
</style>
