import { type FetchStateOptions } from '@base/composables/useAsyncCache'

/**
 * @description get bullet distribution form distribution contract
 * @export
 * @param {FetchStateOptions} [options]
 * @return {*}
 */
export function useBulletDistribution(options?: FetchStateOptions) {
  const store = useDistributionStore()
  const defaultOptions: FetchStateOptions = {
    interval: Number.MAX_VALUE,
  }

  // [ Bullet Distribution Length ]
  const {
    state: bulletDistributionLengthState,
    refresh: refreshBulletDistributionLength,
    isLoading: bulletDistributionLengthLoading,
    isReady: bulletDistributionLengthReady,
  } = store.fetchBulletDistributionLength([], {
    ...defaultOptions,
    ...options,
  })

  // [ Bullet Distribution ]
  const fetchBulletDistribution = ref<
    ReturnType<typeof store.fetchBulletDistribution>[]
  >([])
  watch(bulletDistributionLengthReady, () => {
    if (bulletDistributionLengthReady.value) {
      fetchBulletDistribution.value = new Array(
        bulletDistributionLengthState.value,
      )
        .fill('')
        .map((_, index) => {
          return store.fetchBulletDistribution([index], {
            ...defaultOptions,
            force: true,
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        }) as any[]
    }
  })
  const bulletDistributionState = computed(() =>
    fetchBulletDistribution.value.map((x) => x.state),
  )
  // const refreshBulletDistribution = (force = false) => {
  //   fetchBulletDistribution.value.forEach((x) => x.refresh(force))
  // }
  const bulletDistributionIsLoading = computed(() =>
    fetchBulletDistribution.value.some((x) => x.isLoading),
  )
  const bulletDistributionIsReady = computed(() =>
    fetchBulletDistribution.value.every((x) => x.isReady),
  )

  return {
    bulletDistributionLengthState,
    /** both refresh for bulletDistribution and bulletDistributionLength */
    refreshBulletDistributionLength,
    bulletDistributionLengthLoading,
    bulletDistributionLengthReady,
    bulletDistributionState,
    bulletDistributionIsLoading,
    bulletDistributionIsReady,
  }
}
